<template>
  <b-modal
    name="editor-markdown"
    id="editor-markdown"
    size="xl"
    title=""
    @shown="openModal"
    @hidden="reset"
    hide-footer
    hide-header
  >

    <h5 class="modal-header">Formate aqui o seu texto</h5>
  
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>

    <section v-else>
      <v-md-editor
        v-model="text"
        :toolbar="toolbar"
        :placeholder="$t('editor_markdown.text_340')"
        height="500px"
      ></v-md-editor>
    </section>
    <div class="d-flex justify-content-end mt-3">
      <BaseButton :disabled="loading" class="btn-markdown" variant="black" @click="sendTextEdition">
        {{ $t('editor_markdown.text_1893') }}
      </BaseButton>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "EditorMarkDown",

  data() {
    return {
      loading: false,
      text: "",
      toolbar: {
        save: false,
        toc: false,
        fullscreen: false,
      },
    };
  },
  props: {
    text_parent: {
      type: String,
      default: "",
    },
    from: {
      type: String,
    },
  },

  methods: {
    sendTextEdition() {
      if (this.from == "edit") {
        // EventBus.$emit("textEditMarkDown", this.text);
        this.$emit("updated", this.text)
      } else if (this.from == "new") {
        // EventBus.$emit("textNewMarkDown", this.text);
        this.$emit("created", this.text)
      }
      this.$bvModal.hide("editor-markdown");
    },
    reset() {
      this.text = "";
    },
    openModal() {
      this.text = this.text_parent;
    },
  },
};
</script>

<style scoped>
.v-md-textarea-editor textarea{
  height: 100% !important;
  border: inherit !important;
  width: 100% !important;
  box-shadow: none !important;
}

.btn-top {
  position: absolute;
  top: -65px;
  right: 0;
}

.btn-markdown{
  background: var(--maincolor);
  border: 1px solid var(--maincolor);
}

.modal-header{
  color: var(--fontcolor2) !important;
}

.v-md-editor__toolbar{
  background: var(--maincolor) !important;
}
</style>

