<template>
  <SiteTemplateV2 v-if="clubVersion === 'v2'">
    <div class="createLessons">
      <div class="container" data-anima="top">
        <modal-upload
          :data="data"
          :typeLesson="typeContentSelected"
        ></modal-upload>
        <b-row class="spaceWizzard">
          <b-col cols="12" md="3" class="pt-5">
            <div class="navigate">
              <div class="title title-v2 animationOpacity">
                {{ nameCourse }}
                <button
                  id="viewModuleLesson"
                  class="viewModuleLesson"
                  @click="viewCourse()"
                >
                  Ver Curso
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.375 8.75L12.5 5.625L9.375 2.5"
                      stroke="#C4C4C4"
                      stroke-width="1.8"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2.5 12.5V8.125C2.5 7.46194 2.76339 6.82606 3.23223 6.35725C3.70107 5.88839 4.33696 5.625 5 5.625H12.5"
                      stroke="#C4C4C4"
                      stroke-width="1.8"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <div class="lineDiv"></div>
              <router-link
                :to="'/config_curso/' + cursoAtual"
                class="removeLink"
              >
                <div class="spaceNav">
                  <div class="text">
                    <svg
                      width="20"
                      height="18"
                      viewBox="0 0 20 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V11C0 11.7956 0.316071 12.5587 0.87868 13.1213C1.44129 13.6839 2.20435 14 3 14H9V16H5C4.73478 16 4.48043 16.1054 4.29289 16.2929C4.10536 16.4804 4 16.7348 4 17C4 17.2652 4.10536 17.5196 4.29289 17.7071C4.48043 17.8946 4.73478 18 5 18H15C15.2652 18 15.5196 17.8946 15.7071 17.7071C15.8946 17.5196 16 17.2652 16 17C16 16.7348 15.8946 16.4804 15.7071 16.2929C15.5196 16.1054 15.2652 16 15 16H11V14H17C17.7956 14 18.5587 13.6839 19.1213 13.1213C19.6839 12.5587 20 11.7956 20 11V3C20 2.20435 19.6839 1.44129 19.1213 0.87868C18.5587 0.316071 17.7956 0 17 0ZM18 11C18 11.2652 17.8946 11.5196 17.7071 11.7071C17.5196 11.8946 17.2652 12 17 12H3C2.73478 12 2.48043 11.8946 2.29289 11.7071C2.10536 11.5196 2 11.2652 2 11V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V11Z"
                        fill="#333333"
                      />
                    </svg>
                    Dados do Curso
                  </div>
                  <img src="@/assets/icons/arrow-navigate.svg" />
                </div>
              </router-link>
              <div class="lineDiv"></div>
              <router-link
                :to="'/config_curso/' + cursoAtual + '/module'"
                class="removeLink"
                :class="{ disableLink: existParans }"
              >
                <div class="spaceNav">
                  <div class="text">
                    <svg
                      width="22"
                      height="20"
                      viewBox="0 0 22 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21.47 16.82L20.47 12.96L17.32 1.37003C17.2519 1.11437 17.0853 0.896104 16.8567 0.76303C16.628 0.629955 16.3559 0.592918 16.1 0.66003L12.23 1.66003C12.1376 1.55767 12.0251 1.47552 11.8995 1.41873C11.7739 1.36194 11.6379 1.33174 11.5 1.33003H1.5C1.23478 1.33003 0.98043 1.43539 0.792893 1.62292C0.605357 1.81046 0.5 2.06481 0.5 2.33003V18.33C0.5 18.5952 0.605357 18.8496 0.792893 19.0371C0.98043 19.2247 1.23478 19.33 1.5 19.33H11.5C11.7652 19.33 12.0196 19.2247 12.2071 19.0371C12.3946 18.8496 12.5 18.5952 12.5 18.33V10.33L14.7 18.55C14.7586 18.7682 14.8893 18.9601 15.0709 19.0945C15.2525 19.2288 15.4742 19.2978 15.7 19.29C15.7864 19.2999 15.8736 19.2999 15.96 19.29L20.79 18C20.9177 17.9659 21.0374 17.9068 21.1421 17.8261C21.2468 17.7454 21.3344 17.6448 21.4 17.53C21.5055 17.3088 21.5302 17.0576 21.47 16.82ZM5.47 17.37H2.47V15.37H5.47V17.37ZM5.47 13.37H2.47V7.37003H5.47V13.37ZM5.47 5.37003H2.47V3.37003H5.47V5.37003ZM10.47 17.37H7.47V15.37H10.47V17.37ZM10.47 13.37H7.47V7.37003H10.47V13.37ZM10.47 5.37003H7.47V3.37003H10.47V5.37003ZM12.72 3.63003L15.62 2.85003L16.14 4.78003L13.24 5.56003L12.72 3.63003ZM15.31 13.29L13.76 7.49003L16.66 6.71003L18.21 12.51L15.31 13.29ZM16.31 17.15L15.79 15.22L18.69 14.44L19.21 16.37L16.31 17.15Z"
                        fill="#333333"
                      />
                    </svg>
                    Módulos e Aulas
                  </div>
                  <img src="@/assets/icons/arrow-navigate.svg" />
                </div>
              </router-link>
              <div class="lineDiv"></div>
              <router-link
                :to="'/config_curso/class/' + cursoAtual + ''"
                class="removeLink"
                :class="{ disableLink: existParans }"
              >
                <div class="spaceNav">
                  <div class="text">
                    <svg
                      width="26"
                      height="22"
                      viewBox="0 0 26 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M24 20V18C23.9993 17.1137 23.7044 16.2528 23.1614 15.5523C22.6184 14.8519 21.8581 14.3516 21 14.13M18 20V18C18 16.9391 17.5786 15.9217 16.8284 15.1716C16.0783 14.4214 15.0609 14 14 14H6C4.93913 14 3.92172 14.4214 3.17157 15.1716C2.42143 15.9217 2 16.9391 2 18V20M17 2.13C17.8604 2.3503 18.623 2.8507 19.1676 3.55231C19.7122 4.25392 20.0078 5.11683 20.0078 6.005C20.0078 6.89317 19.7122 7.75608 19.1676 8.45769C18.623 9.1593 17.8604 9.6597 17 9.88M14 6C14 8.20914 12.2091 10 10 10C7.79086 10 6 8.20914 6 6C6 3.79086 7.79086 2 10 2C12.2091 2 14 3.79086 14 6Z"
                        stroke="#333333"
                        stroke-width="2.3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Turmas
                  </div>
                  <img src="@/assets/icons/arrow-navigate.svg" />
                </div>
              </router-link>
              <div class="lineDiv"></div>
              <router-link
                :to="'/create-certificates/' + cursoAtual + ''"
                class="removeLink"
                :class="{ disableLink: existParans }"
              >
                <div class="spaceNav">
                  <div class="text">
                    <svg
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.43496 22.9609H16.6119H8.43496ZM12.5234 22.9609V16.4194V22.9609ZM19.065 10.6955C19.065 8.10753 19.0609 3.81517 19.0588 2.51862C19.0588 2.30175 18.9727 2.09377 18.8193 1.94042C18.666 1.78708 18.458 1.70093 18.2411 1.70093L6.80417 1.71422C6.58775 1.71421 6.38016 1.80001 6.22688 1.95281C6.07361 2.1056 5.98716 2.31293 5.98648 2.52935C5.98648 4.09216 5.97984 9.13067 5.97984 10.6955C5.97984 13.9806 10.2216 16.4194 12.5214 16.4194C14.8211 16.4194 19.065 13.9806 19.065 10.6955ZM19.065 10.6955C21.4388 10.6955 23.1534 7.79374 23.1534 4.97169V4.154H19.065V10.6955ZM5.98188 4.154H1.89343V4.97169C1.89343 7.79374 3.60803 10.6955 5.98188 10.6955V4.154Z"
                        stroke="#333333"
                        stroke-width="1.8"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Certificado
                  </div>
                  <img src="@/assets/icons/arrow-navigate.svg" />
                </div>
              </router-link>
              <div class="lineDiv"></div>
              <router-link
                :to="'/config_curso/vendas/' + cursoAtual + ''"
                class="removeLink"
                :class="{ disableLink: existParans }"
              >
                <div class="spaceNav">
                  <div class="text">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 2L3 6V20C3 20.5304 3.21071 21.0391 3.58579 21.4142C3.96086 21.7893 4.46957 22 5 22H19C19.5304 22 20.0391 21.7893 20.4142 21.4142C20.7893 21.0391 21 20.5304 21 20V6L18 2H6Z"
                        stroke="#333333"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16 10C16 11.0609 15.5786 12.0783 14.8284 12.8284C14.0783 13.5786 13.0609 14 12 14C10.9391 14 9.92172 13.5786 9.17157 12.8284C8.42143 12.0783 8 11.0609 8 10"
                        stroke="#333333"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M3 6H21"
                        stroke="#333333"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    Vendas
                  </div>
                  <img src="@/assets/icons/arrow-navigate.svg" />
                </div>
              </router-link>
              <div class="lineDiv"></div>

              <div class="spaceBtn">
                <toggle-button
                  :sync="true"
                  :labels="{ checked: 'Ativo', unchecked: 'Inativo' }"
                  @change="enventSwitchStatus"
                  v-model="myDataVariable"
                />
              </div>
            </div>
          </b-col>
          <b-col cols="12" md="9" class="pt-5">
            <div class="containerWizzard">
              <div class="title title-v2">{{ ModuleLessons.title }}</div>
            </div>
            <div class="containerWizzardInputs" v-if="!loading">
              <div class="spaceModuleCard" id="step12">
                <div class="actionModule">
                  <div class="titleModule title-v2">Gerenciamento de Aulas</div>
                </div>
                <div>
                  <draggable
                    :list="AllAulas"
                    :disabled="!enabled"
                    class="list-group"
                    ghost-class="ghost"
                    @end="onEnd"
                  >
                    <div
                      class="spaceInputs removemb viewAulas"
                      v-for="(lesson, index) in AllAulas"
                      :key="lesson.id"
                      :data-id="lesson.id"
                    >
                      <span v-if="editLesson === false && addAula === false">
                        <span
                          class="textSubcategory"
                          v-if="
                            AllAulas[index - 1] === undefined &&
                            lesson.small_category !== undefined
                          "
                          >{{ lesson.small_category }}</span
                        >
                        <span
                          class="textSubcategory"
                          v-else-if="
                            AllAulas[index - 1].small_category !==
                            lesson.small_category
                          "
                          >{{ lesson.small_category }}</span
                        >
                      </span>
                      <div
                        class="flexAula"
                        :class="{ marginSubCategory: lesson.small_category }"
                        v-if="editLesson === false && addAula === false"
                      >
                        <div class="flex">
                          <svg
                            class="play"
                            width="10"
                            height="12"
                            viewBox="0 0 10 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0.538086 1.04162C0.538086 0.250458 1.41333 -0.227381 2.07884 0.200448L9.17349 4.76129C9.78582 5.15493 9.78581 6.05001 9.17349 6.44365L2.07884 11.0045C1.41333 11.4323 0.538086 10.9545 0.538086 10.1633V1.04162Z"
                              fill="#C4C4C4"
                            />
                          </svg>
                          <div
                            v-if="lesson.status === 'draft'"
                            class="textAula title-v2"
                            @click="editLessonFuntion(lesson)"
                          >
                            {{ lesson.title }}
                            <span class="textAula2 title-v2">(Rascunho)</span>
                          </div>
                          <div
                            v-else
                            class="textAula title-v2"
                            @click="editLessonFuntion(lesson)"
                          >
                            {{ lesson.title }}
                          </div>
                        </div>
                        <div class="flex">
                          <div class="timeAula">
                            {{ converteDuration(lesson.duration) }}
                          </div>
                          <div class="actions actionAula">
                            <svg
                              class="action"
                              width="19"
                              height="5"
                              viewBox="0 0 19 5"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9.83667 3.83643C10.389 3.83643 10.8367 3.38871 10.8367 2.83643C10.8367 2.28414 10.389 1.83643 9.83667 1.83643C9.28439 1.83643 8.83667 2.28414 8.83667 2.83643C8.83667 3.38871 9.28439 3.83643 9.83667 3.83643Z"
                                stroke="#C4C4C4"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M16.8367 3.83643C17.389 3.83643 17.8367 3.38871 17.8367 2.83643C17.8367 2.28414 17.389 1.83643 16.8367 1.83643C16.2844 1.83643 15.8367 2.28414 15.8367 2.83643C15.8367 3.38871 16.2844 3.83643 16.8367 3.83643Z"
                                stroke="#C4C4C4"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M2.83667 3.83643C3.38895 3.83643 3.83667 3.38871 3.83667 2.83643C3.83667 2.28414 3.38895 1.83643 2.83667 1.83643C2.28439 1.83643 1.83667 2.28414 1.83667 2.83643C1.83667 3.38871 2.28439 3.83643 2.83667 3.83643Z"
                                stroke="#C4C4C4"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            <b-dropdown
                              id="dropdown-dropright"
                              dropright
                              text=""
                              class="m-2"
                            >
                              <b-dropdown-item
                                @click="editLessonFuntion(lesson)"
                                >Editar Aula</b-dropdown-item
                              >
                              <b-dropdown-item @click="viewLesson(lesson.id)"
                                >Ver Aula</b-dropdown-item
                              >
                              <b-dropdown-item @click="deleteLesson(lesson.id)"
                                >Deletar Aula</b-dropdown-item
                              >
                            </b-dropdown>
                            <button
                              id="viewModuleLesson2"
                              class="viewModuleLesson"
                              @click="viewLesson(lesson.id)"
                            >
                              Ver Aula
                              <svg
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.375 8.75L12.5 5.625L9.375 2.5"
                                  stroke="#C4C4C4"
                                  stroke-width="1.8"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M2.5 12.5V8.125C2.5 7.46194 2.76339 6.82606 3.23223 6.35725C3.70107 5.88839 4.33696 5.625 5 5.625H12.5"
                                  stroke="#C4C4C4"
                                  stroke-width="1.8"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </draggable>
                  <div
                    class="spaceInputs removemb animationOpacity"
                    v-if="addAula === false || addAula === ''"
                  >
                    <b-form-group id="step13">
                      <div class="btn-openModal" @click="openCreateAula()">
                        <p class="text">Clique e adicione uma Aula</p>
                      </div>
                    </b-form-group>
                  </div>
                </div>

                <div v-if="addAula === true" class="animationOpacity">
                  <div class="spaceInputs">
                    <b-form-group
                      label="Nome da Aula"
                      label-for="name-aula"
                      id="step14"
                    >
                      <b-form-input
                        v-model="nameAula"
                        placeholder="Nome da Aula"
                      ></b-form-input>
                    </b-form-group>
                  </div>

                  <div
                    class="spaceInputs removemb"
                    v-if="mediaTypeLesson !== 'quiz' || !editLesson"
                  >
                  <!--versao 2-->
                    <b-form-group
                      label="Tipo de conteúdo"
                      label-for="name-aula"
                    >
                      <b-form-select
                        v-model="typeContentSelected"
                        :options="optionsTypeContent"
                        @change="verifyIfIsQuiz"
                        placeholder="Nome da Aula"
                      ></b-form-select>
                    </b-form-group>
                  </div>

                  <Qiz
                    v-if="typeContentSelected === 'QUIZ'"
                    @addedQuestion="addedQuestion"
                    @removedQuestion="removedQuestion"
                    :questions="this.questions"
                    :inEdit="this.editLesson"
                    @closeModalOptions="closeModalOptions"
                  />
                  <div>
                    <div
                      class="spaceInputs removemb"
                      v-if="
                        typeContentSelected === 'VIDEO' ||
                        typeContentSelected === 'PDF' ||
                        typeContentSelected === 'AUDIO'
                      "
                    >
                      <b-form-group
                        label="Arquivo da Aula"
                        label-for="name-aula"
                        id="step15"
                      >


                      
                        <div
                          :disabled="mediaTypeLesson"
                          class="btn-openModal"
                          @click="typeContentSelected === 'PDF' ? showModalUploadDocument() : showModalUpload(ModuleLessons.id)"
                          v-if="midialessonpreview === null"
                        >
                          <p class="text">
                            Clique e adicione um
                            {{
                              typeContentSelected === 'AUDIO'
                                ? 'Audio' 
                                : typeContentSelected === 'PDF' 
                                ? 'PDF'
                                : 'Vídeo'
                            }}
                          </p>
                        </div>


                        
                        <b-form-group label="" label-for="complement" v-else-if="mediaTypeLesson === 'pdf'">
                          <!-- <div
                            class="btn-openModal"
                            @click="showModalUploadDocument()"
                            v-if="
                              !midiaPdfUrl
                            "
                          >
                            <p class="text">TESTE PDF</p>
                          </div> -->
                          <div
                            class="btn-openModalPreview"
                            
                          >
                            <iframe
                              v-if="mediaTypeLesson === 'pdf' &&
                              midialesson !== 'pdf' && midiaPdfUrl"
                              class="imagePreviewCapa animationOpacity"
                              height="100%"
                              width="100%"
                              :src="midiaPdfUrl"
                            ></iframe>
                            <img
                              v-else
                              class="imagePreviewCapa animationOpacity"
                              :src="midiaPdfUrl"
                              :alt="midiaPdfUrl"
                            />
                            <div
                                class="removeAttr" @click="removeCourse"
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M18 6L6 18"
                                    stroke="black"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M6 6L18 18"
                                    stroke="black"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </div>
                          </div>
                        </b-form-group>

                        
                        <div class="" v-else-if="mediaTypeLesson === 'audio'">
                          <div
                            class="btn-openModal mt-2 mb-3"
                            @click="showModalUpload(ModuleLessons.id)"
                            v-if="
                              mediaTypeLesson === 'audio' &&
                              midialessonpreview !== null
                            "
                          >
                            <p class="text">Clique e adicione um Audio</p>
                          </div>
                          <div v-for="item in AllAudios" :key="item.id">
                            <div class="btn-openModalPreview mb-2">
                              <div>
                                <img
                                  src="@/assets/img/audio.png"
                                  class="imgAudio"
                                  alt="audio"
                                />
                                <p>{{ item.title }}</p>
                              </div>
                              <div
                                class="removeAttr"
                                @click="removeAudio(item.id)"
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M18 6L6 18"
                                    stroke="black"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M6 6L18 18"
                                    stroke="black"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>

                       

                        <div class="btn-openModalPreview" v-else>
                          <iframe
                            class="imagePreviewYt animationOpacity"
                            height="100%"
                            v-if="
                              mediaTypeLesson === 'panda' &&
                              midialesson !== 'panda'
                            "
                            width="50%"
                            :src="midialesson + '&controls=play-larg'"
                            style="border: 0; pointer-events: all"
                          ></iframe>
                          <div
                            v-else-if="
                              mediaTypeLesson === 'panda' &&
                              midialesson === 'panda'
                            "
                          >
                            <img
                              src="@/assets/img/iframe.png"
                              class="imgAudio"
                              alt="audio"
                            />
                          </div>
                          <div v-else-if="mediaTypeLesson === 'audio'">
                            <img
                              src="@/assets/img/audio.png"
                              class="imgAudio"
                              alt="audio"
                            />
                            <p class="title-v2">{{ midialessontitle }}</p>
                          </div>
                          <img
                            v-else
                            class="imagePreviewYt animationOpacity"
                            @click="showModalUploadItem(ModuleLessons.id)"
                            :src="midialessonpreview"
                            :alt="midialessonpreview"
                          />
                          <div
                            class="removeLessonVideo"
                            @click="removeCourse()"
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M18 6L6 18"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M6 6L18 18"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                        </div>
                      </b-form-group>
                    </div>
                    <div class="lineDivAtt"></div>
                    <div class="spaceInputs2">
                      <b-form-group
                        label="Categoria (Opcional)"
                        label-for="name-aula"
                      >
                        <div>
                          <multiselect
                            noOptions="Sem Categoria, Escreva uma!"
                            tag-placeholder="Adicionar nova Categoria"
                            @tag="addTag"
                            placeholder="Selecione uma Categoria"
                            selectedLabel=""
                            deselectLabel="Pressione Enter"
                            :close-on-select="true"
                            selectLabel="Pressione Enter"
                            track-by="id"
                            :multiple="false"
                            :taggable="true"
                            v-model="categoryLesson"
                            :options="optionsLessonCategory"
                          ></multiselect>
                        </div>
                      </b-form-group>
                    </div>

                    <div
                      class="spaceInputs removemb"
                      v-if="
                        clubVersion !== 'v2' &&
                        (typeContentSelected === 'VIDEO' ||
                          typeContentSelected === 'AUDIO')
                      "
                    >
                      <b-form-group
                        label="Thumb Customizada (Opcional)"
                        label-for="name-aula"
                      >
                        <div
                          class="btn-openModal"
                          @click="showModalUploadThumb(ModuleLessons.id)"
                          v-if="midialessonthumb === null"
                        >
                          <p class="text">Clique e adicione uma Thumb</p>
                        </div>
                        <div
                          class="btn-openModalPreview"
                          @click="showModalUploadThumb(ModuleLessons.id)"
                          v-else
                        >
                          <img
                            class="imagePreviewYt animationOpacity"
                            :src="midialessonthumb.url"
                            :alt="midialessonthumb.url"
                          />
                        </div>
                      </b-form-group>
                    </div>
                    <div
                      class="spaceInputs"
                      v-if="
                        typeContentSelected === 'VIDEO' ||
                        typeContentSelected === 'AUDIO' ||
                        typeContentSelected === 'PDF' ||
                        typeContentSelected === 'TEXT'
                      "
                    >
                      <!-- Description -->
                      <b-form-group
                        class="description-container"
                        label="Descrição"
                        label-for="description"
                      >
                        <div
                          class="description-length"
                          :class="{ ok: descriptionLessonLength >= 200 }"
                          v-if="descriptionLessonLength"
                        >
                          {{ descriptionLessonLength }}
                        </div>

                        <b-form-textarea
                          class="description-textarea"
                          id="description"
                          name="description"
                          v-model="descricaoAula"
                          style="overflow-y: scroll !important;"
                          placeholder="Descrição da aula"
                        ></b-form-textarea>
                        <div class="mark-down d-flex justify-content-between mt-2">
                          <p class="info-mark">Descrição formatada em MarkDown</p>
                          <div
                            class="open-mark"
                            @click="openModal('editor-markdown')"
                          >
                            Formatar descrição
                        </div>
                        </div>
                      </b-form-group>
                      <EditorMarkDown :text_parent="descricaoAula" from="new" @created="descricaoAula = $event" @updated="descricaoAula = $event" />

                    </div>
                    <div
                      class="spaceInputs removemb"
                      v-if="
                        typeContentSelected === 'VIDEO' ||
                        typeContentSelected === 'AUDIO' ||
                        typeContentSelected === 'PDF' ||
                        typeContentSelected === 'TEXT'
                      "
                    >
                      <div>
                        <div class="lineDivAtt"></div>
                        <b-form-group
                          label="Arquivos Adicionais"
                          label-for="complement"
                        >
                          <div class="totalAtt">
                            Total de Arquivos: {{ quantidadeAtt }}
                          </div>
                          <div>
                            <div
                              class="btn-openModalPreview spaceAttList"
                              v-for="attach in AllAttachments"
                              :key="attach.id"
                            >
                              <iframe
                                v-if="attach.mime === 'application/pdf'"
                                class="imagePreviewCapa animationOpacity"
                                height="100%"
                                width="100%"
                                :src="attach.cdn_url"
                              ></iframe>
                              <div v-else-if="attach.mime === 'audio/mpeg'">
                                <img
                                  src="@/assets/img/audio.png"
                                  class="imgAudio"
                                  alt="audio"
                                />
                                <p class="title-v2">{{ attach.title }}</p>
                              </div>
                              <img
                                loading="lazy"
                                class="imagePreviewCapa animationOpacity"
                                v-else-if="attach.mime === 'application/zip'"
                                src="https://app.greenn.club/zip.png"
                                :alt="attach.title"
                              />
                              <img
                                loading="lazy"
                                class="imagePreviewCapa animationOpacity"
                                v-else-if="
                                  attach.mime === 'application/msword' ||
                                  attach.mime ===
                                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                "
                                src="https://app.greenn.club/doc.png"
                                :alt="attach.title"
                              />
                              <img
                                loading="lazy"
                                class="imagePreviewCapa animationOpacity"
                                v-else-if="
                                  attach.mime === 'application/vnd.ms-excel' ||
                                  attach.mime ===
                                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                "
                                src="https://app.greenn.club/xls.png"
                                :alt="attach.title"
                              />
                              <img
                                v-else
                                class="imagePreviewCapa animationOpacity"
                                :src="attach.cdn_url"
                                :alt="attach.title"
                              />
                              <div
                                class="removeAttr"
                                @click="removeAttr(attach.id)"
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M18 6L6 18"
                                    stroke="black"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M6 6L18 18"
                                    stroke="black"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                        </b-form-group>
                      </div>
                      <div>
                        <b-form-group label="" label-for="complement">
                          <div
                            class="btn-openModal"
                            @click="showModalUploadAditional()"
                            v-if="
                              midiaaditionalurl === '' ||
                              midiaaditionalurl === null
                            "
                          >
                            <p class="text">Faça o Upload dos Complementos</p>
                          </div>
                          <div
                            class="btn-openModalPreview"
                            @click="showModalUploadAditional()"
                            v-else
                          >
                            <iframe
                              v-if="midiaaditionalurltype === 'application/pdf'"
                              class="imagePreviewCapa animationOpacity"
                              height="100%"
                              width="100%"
                              :src="midiaaditionalurl"
                            ></iframe>
                            <img
                              v-else
                              class="imagePreviewCapa animationOpacity"
                              :src="midiaaditionalurl"
                              :alt="midiaaditionalurl"
                            />
                          </div>
                        </b-form-group>
                        <div
                          v-if="
                            midiaaditionalurl === '' ||
                            midiaaditionalurl === null
                          "
                        ></div>
                        <div class="lineDivAtt"></div>
                      </div>
                    </div>
                    <div class="spaceInputs" v-if="editLesson === false">
                      <div class="flexBtn">
                        <button
                          class="btn-cancela"
                          @click.prevent="notSaveLesson()"
                        >
                          Cancelar
                        </button>
                        <button
                          class="btn-rascunho"
                          @click.prevent="createAula(ModuleLessons.id, 'draft')"
                        >
                          Salvar Rascunho
                        </button>
                      </div>
                      <button
                        class="btn-criar"
                        id="step17"
                        @click.prevent="
                          createAula(ModuleLessons.id, 'published')
                        "
                      >
                        Publicar Aula
                      </button>
                    </div>
                    <div class="spaceInputs" v-else>
                      <div class="flexBtn">
                        <button
                          class="btn-cancela"
                          @click.prevent="notSaveLesson()"
                        >
                          Cancelar
                        </button>
                        <button
                          class="btn-rascunho"
                          @click.prevent="saveEditLesson('draft')"
                        >
                          Salvar Rascunho
                        </button>
                      </div>
                      <button
                        class="btn-criar"
                        @click.prevent="saveEditLesson('published')"
                      >
                        Publicar Aula
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center mt-5" v-if="loading">
              <b-spinner label="Loading..."></b-spinner>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </SiteTemplateV2>
  <SiteTemplate v-else>
    <div class="createLessons">
      <div class="container" data-anima="top">
        <modal-upload
          :data="data"
          :typeLesson="typeContentSelected"
        ></modal-upload>
        <b-row class="spaceWizzard">
          <b-col cols="12" md="3" class="pt-5">
            <div class="navigate">
              <div class="title animationOpacity">
                {{ nameCourse }}
                <button
                  id="viewModuleLesson"
                  class="viewModuleLesson"
                  @click="viewCourse()"
                >
                  Ver Curso
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.375 8.75L12.5 5.625L9.375 2.5"
                      stroke="#C4C4C4"
                      stroke-width="1.8"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2.5 12.5V8.125C2.5 7.46194 2.76339 6.82606 3.23223 6.35725C3.70107 5.88839 4.33696 5.625 5 5.625H12.5"
                      stroke="#C4C4C4"
                      stroke-width="1.8"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <div class="lineDiv"></div>
              <router-link
                :to="'/config_curso/' + cursoAtual"
                class="removeLink"
              >
                <div class="spaceNav">
                  <div class="text">
                    <svg
                      width="20"
                      height="18"
                      viewBox="0 0 20 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V11C0 11.7956 0.316071 12.5587 0.87868 13.1213C1.44129 13.6839 2.20435 14 3 14H9V16H5C4.73478 16 4.48043 16.1054 4.29289 16.2929C4.10536 16.4804 4 16.7348 4 17C4 17.2652 4.10536 17.5196 4.29289 17.7071C4.48043 17.8946 4.73478 18 5 18H15C15.2652 18 15.5196 17.8946 15.7071 17.7071C15.8946 17.5196 16 17.2652 16 17C16 16.7348 15.8946 16.4804 15.7071 16.2929C15.5196 16.1054 15.2652 16 15 16H11V14H17C17.7956 14 18.5587 13.6839 19.1213 13.1213C19.6839 12.5587 20 11.7956 20 11V3C20 2.20435 19.6839 1.44129 19.1213 0.87868C18.5587 0.316071 17.7956 0 17 0ZM18 11C18 11.2652 17.8946 11.5196 17.7071 11.7071C17.5196 11.8946 17.2652 12 17 12H3C2.73478 12 2.48043 11.8946 2.29289 11.7071C2.10536 11.5196 2 11.2652 2 11V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V11Z"
                        fill="#333333"
                      />
                    </svg>
                    Dados do Curso
                  </div>
                  <img src="@/assets/icons/arrow-navigate.svg" />
                </div>
              </router-link>
              <div class="lineDiv"></div>
              <router-link
                :to="'/config_curso/' + cursoAtual + '/module'"
                class="removeLink"
                :class="{ disableLink: existParans }"
              >
                <div class="spaceNav">
                  <div class="text">
                    <svg
                      width="22"
                      height="20"
                      viewBox="0 0 22 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21.47 16.82L20.47 12.96L17.32 1.37003C17.2519 1.11437 17.0853 0.896104 16.8567 0.76303C16.628 0.629955 16.3559 0.592918 16.1 0.66003L12.23 1.66003C12.1376 1.55767 12.0251 1.47552 11.8995 1.41873C11.7739 1.36194 11.6379 1.33174 11.5 1.33003H1.5C1.23478 1.33003 0.98043 1.43539 0.792893 1.62292C0.605357 1.81046 0.5 2.06481 0.5 2.33003V18.33C0.5 18.5952 0.605357 18.8496 0.792893 19.0371C0.98043 19.2247 1.23478 19.33 1.5 19.33H11.5C11.7652 19.33 12.0196 19.2247 12.2071 19.0371C12.3946 18.8496 12.5 18.5952 12.5 18.33V10.33L14.7 18.55C14.7586 18.7682 14.8893 18.9601 15.0709 19.0945C15.2525 19.2288 15.4742 19.2978 15.7 19.29C15.7864 19.2999 15.8736 19.2999 15.96 19.29L20.79 18C20.9177 17.9659 21.0374 17.9068 21.1421 17.8261C21.2468 17.7454 21.3344 17.6448 21.4 17.53C21.5055 17.3088 21.5302 17.0576 21.47 16.82ZM5.47 17.37H2.47V15.37H5.47V17.37ZM5.47 13.37H2.47V7.37003H5.47V13.37ZM5.47 5.37003H2.47V3.37003H5.47V5.37003ZM10.47 17.37H7.47V15.37H10.47V17.37ZM10.47 13.37H7.47V7.37003H10.47V13.37ZM10.47 5.37003H7.47V3.37003H10.47V5.37003ZM12.72 3.63003L15.62 2.85003L16.14 4.78003L13.24 5.56003L12.72 3.63003ZM15.31 13.29L13.76 7.49003L16.66 6.71003L18.21 12.51L15.31 13.29ZM16.31 17.15L15.79 15.22L18.69 14.44L19.21 16.37L16.31 17.15Z"
                        fill="#333333"
                      />
                    </svg>
                    Módulos e Aulas
                  </div>
                  <img src="@/assets/icons/arrow-navigate.svg" />
                </div>
              </router-link>
              <div class="lineDiv"></div>
              <router-link
                :to="'/config_curso/class/' + cursoAtual + ''"
                class="removeLink"
                :class="{ disableLink: existParans }"
              >
                <div class="spaceNav">
                  <div class="text">
                    <svg
                      width="26"
                      height="22"
                      viewBox="0 0 26 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M24 20V18C23.9993 17.1137 23.7044 16.2528 23.1614 15.5523C22.6184 14.8519 21.8581 14.3516 21 14.13M18 20V18C18 16.9391 17.5786 15.9217 16.8284 15.1716C16.0783 14.4214 15.0609 14 14 14H6C4.93913 14 3.92172 14.4214 3.17157 15.1716C2.42143 15.9217 2 16.9391 2 18V20M17 2.13C17.8604 2.3503 18.623 2.8507 19.1676 3.55231C19.7122 4.25392 20.0078 5.11683 20.0078 6.005C20.0078 6.89317 19.7122 7.75608 19.1676 8.45769C18.623 9.1593 17.8604 9.6597 17 9.88M14 6C14 8.20914 12.2091 10 10 10C7.79086 10 6 8.20914 6 6C6 3.79086 7.79086 2 10 2C12.2091 2 14 3.79086 14 6Z"
                        stroke="#333333"
                        stroke-width="2.3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Turmas
                  </div>
                  <img src="@/assets/icons/arrow-navigate.svg" />
                </div>
              </router-link>
              <div class="lineDiv"></div>
              <router-link
                :to="'/create-certificates/' + cursoAtual + ''"
                class="removeLink"
                :class="{ disableLink: existParans }"
              >
                <div class="spaceNav">
                  <div class="text">
                    <svg
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.43496 22.9609H16.6119H8.43496ZM12.5234 22.9609V16.4194V22.9609ZM19.065 10.6955C19.065 8.10753 19.0609 3.81517 19.0588 2.51862C19.0588 2.30175 18.9727 2.09377 18.8193 1.94042C18.666 1.78708 18.458 1.70093 18.2411 1.70093L6.80417 1.71422C6.58775 1.71421 6.38016 1.80001 6.22688 1.95281C6.07361 2.1056 5.98716 2.31293 5.98648 2.52935C5.98648 4.09216 5.97984 9.13067 5.97984 10.6955C5.97984 13.9806 10.2216 16.4194 12.5214 16.4194C14.8211 16.4194 19.065 13.9806 19.065 10.6955ZM19.065 10.6955C21.4388 10.6955 23.1534 7.79374 23.1534 4.97169V4.154H19.065V10.6955ZM5.98188 4.154H1.89343V4.97169C1.89343 7.79374 3.60803 10.6955 5.98188 10.6955V4.154Z"
                        stroke="#333333"
                        stroke-width="1.8"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Certificado
                  </div>
                  <img src="@/assets/icons/arrow-navigate.svg" />
                </div>
              </router-link>
              <!-- <div class="lineDiv"></div>
                    <router-link :to="'/config_curso/emails/'+cursoAtual+''" class="removeLink" :class="{disableLink:existParans}">
                        <div class="spaceNav">
                            <div class="text"><svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V13C0 13.7956 0.316071 14.5587 0.87868 15.1213C1.44129 15.6839 2.20435 16 3 16H17C17.7956 16 18.5587 15.6839 19.1213 15.1213C19.6839 14.5587 20 13.7956 20 13V3C20 2.20435 19.6839 1.44129 19.1213 0.87868C18.5587 0.316071 17.7956 0 17 0ZM3 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3L10 7.88L2 3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2ZM18 13C18 13.2652 17.8946 13.5196 17.7071 13.7071C17.5196 13.8946 17.2652 14 17 14H3C2.73478 14 2.48043 13.8946 2.29289 13.7071C2.10536 13.5196 2 13.2652 2 13V5.28L9.48 9.85C9.63202 9.93777 9.80446 9.98397 9.98 9.98397C10.1555 9.98397 10.328 9.93777 10.48 9.85L18 5.28V13Z" fill="#333333"/>
</svg>
  E-mails</div>
                            <img src="@/assets/icons/arrow-navigate.svg">
                        </div>
                    </router-link> -->
              <div class="lineDiv"></div>
              <router-link
                :to="'/config_curso/vendas/' + cursoAtual + ''"
                class="removeLink"
                :class="{ disableLink: existParans }"
              >
                <div class="spaceNav">
                  <div class="text">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 2L3 6V20C3 20.5304 3.21071 21.0391 3.58579 21.4142C3.96086 21.7893 4.46957 22 5 22H19C19.5304 22 20.0391 21.7893 20.4142 21.4142C20.7893 21.0391 21 20.5304 21 20V6L18 2H6Z"
                        stroke="#333333"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16 10C16 11.0609 15.5786 12.0783 14.8284 12.8284C14.0783 13.5786 13.0609 14 12 14C10.9391 14 9.92172 13.5786 9.17157 12.8284C8.42143 12.0783 8 11.0609 8 10"
                        stroke="#333333"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M3 6H21"
                        stroke="#333333"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    Vendas
                  </div>
                  <img src="@/assets/icons/arrow-navigate.svg" />
                </div>
              </router-link>
              <div class="lineDiv"></div>

              <div class="spaceBtn">
                <toggle-button
                  :sync="true"
                  :labels="{ checked: 'Ativo', unchecked: 'Inativo' }"
                  @change="enventSwitchStatus"
                  v-model="myDataVariable"
                />
              </div>
            </div>
          </b-col>
          <b-col cols="12" md="9" class="pt-5">
            <div class="containerWizzard">
              <div class="title">{{ ModuleLessons.title }}</div>
            </div>
            <div class="containerWizzardInputs" v-if="!loading">
              <div class="spaceModuleCard" id="step12">
                <div class="actionModule">
                  <div class="titleModule">Gerenciamento de Aulas</div>
                </div>
                <div>
                  <draggable
                    :list="AllAulas"
                    :disabled="!enabled"
                    class="list-group"
                    ghost-class="ghost"
                    @end="onEnd"
                  >
                    <div
                      class="spaceInputs removemb viewAulas"
                      v-for="(lesson, index) in AllAulas"
                      :key="lesson.id"
                      :data-id="lesson.id"
                    >
                      <span v-if="editLesson === false && addAula === false">
                        <span
                          class="textSubcategory"
                          v-if="
                            AllAulas[index - 1] === undefined &&
                            lesson.small_category !== undefined
                          "
                          >{{ lesson.small_category }}</span
                        >
                        <span
                          class="textSubcategory"
                          v-else-if="
                            AllAulas[index - 1].small_category !==
                            lesson.small_category
                          "
                          >{{ lesson.small_category }}</span
                        >
                      </span>
                      <div
                        class="flexAula"
                        :class="{ marginSubCategory: lesson.small_category }"
                        v-if="editLesson === false && addAula === false"
                      >
                        <div class="flex">
                          <svg
                            class="play"
                            width="10"
                            height="12"
                            viewBox="0 0 10 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0.538086 1.04162C0.538086 0.250458 1.41333 -0.227381 2.07884 0.200448L9.17349 4.76129C9.78582 5.15493 9.78581 6.05001 9.17349 6.44365L2.07884 11.0045C1.41333 11.4323 0.538086 10.9545 0.538086 10.1633V1.04162Z"
                              fill="#C4C4C4"
                            />
                          </svg>
                          <div
                            v-if="lesson.status === 'draft'"
                            class="textAula"
                            @click="editLessonFuntion(lesson)"
                          >
                            {{ lesson.title }}
                            <span class="textAula2">(Rascunho)</span>
                          </div>
                          <div
                            v-else
                            class="textAula"
                            @click="editLessonFuntion(lesson)"
                          >
                            {{ lesson.title }}
                          </div>
                        </div>
                        <div class="flex">
                          <div class="timeAula">
                            {{ converteDuration(lesson.duration) }}
                          </div>
                          <div class="actions actionAula">
                            <svg
                              class="action"
                              width="19"
                              height="5"
                              viewBox="0 0 19 5"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9.83667 3.83643C10.389 3.83643 10.8367 3.38871 10.8367 2.83643C10.8367 2.28414 10.389 1.83643 9.83667 1.83643C9.28439 1.83643 8.83667 2.28414 8.83667 2.83643C8.83667 3.38871 9.28439 3.83643 9.83667 3.83643Z"
                                stroke="#C4C4C4"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M16.8367 3.83643C17.389 3.83643 17.8367 3.38871 17.8367 2.83643C17.8367 2.28414 17.389 1.83643 16.8367 1.83643C16.2844 1.83643 15.8367 2.28414 15.8367 2.83643C15.8367 3.38871 16.2844 3.83643 16.8367 3.83643Z"
                                stroke="#C4C4C4"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M2.83667 3.83643C3.38895 3.83643 3.83667 3.38871 3.83667 2.83643C3.83667 2.28414 3.38895 1.83643 2.83667 1.83643C2.28439 1.83643 1.83667 2.28414 1.83667 2.83643C1.83667 3.38871 2.28439 3.83643 2.83667 3.83643Z"
                                stroke="#C4C4C4"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            <b-dropdown
                              id="dropdown-dropright"
                              dropright
                              text=""
                              class="m-2"
                            >
                              <b-dropdown-item
                                @click="editLessonFuntion(lesson)"
                                >Editar Aula</b-dropdown-item
                              >
                              <b-dropdown-item @click="viewLesson(lesson.id)"
                                >Ver Aula</b-dropdown-item
                              >
                              <b-dropdown-item @click="deleteLesson(lesson.id)"
                                >Deletar Aula</b-dropdown-item
                              >
                            </b-dropdown>
                            <button
                              id="viewModuleLesson2"
                              class="viewModuleLesson"
                              @click="viewLesson(lesson.id)"
                            >
                              Ver Aula
                              <svg
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.375 8.75L12.5 5.625L9.375 2.5"
                                  stroke="#C4C4C4"
                                  stroke-width="1.8"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M2.5 12.5V8.125C2.5 7.46194 2.76339 6.82606 3.23223 6.35725C3.70107 5.88839 4.33696 5.625 5 5.625H12.5"
                                  stroke="#C4C4C4"
                                  stroke-width="1.8"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </draggable>
                  <div
                    class="spaceInputs removemb animationOpacity"
                    v-if="addAula === false || addAula === ''"
                  >
                    <b-form-group id="step13">
                      <div class="btn-openModal" @click="openCreateAula()">
                        <p class="text">Clique e adicione uma Aula</p>
                      </div>
                    </b-form-group>
                  </div>
                </div>

                <div v-if="addAula === true" class="animationOpacity">
                  <div class="spaceInputs">
                    <b-form-group
                      label="Nome da Aula"
                      label-for="name-aula"
                      id="step14"
                    >
                      <b-form-input
                        v-model="nameAula"
                        placeholder="Nome da Aula"
                      ></b-form-input>
                    </b-form-group>
                  </div>

                  <div
                    class="spaceInputs removemb"
                    v-if="mediaTypeLesson !== 'quiz' || !editLesson"
                  >
                    <b-form-group
                      label="Tipo de conteúdo"
                      label-for="name-aula"
                    >
                      <b-form-select
                        v-model="typeContentSelected"
                        :options="optionsTypeContent"
                        @change="clearMidiaLesson"
                        placeholder="Nome da Aula"
                      ></b-form-select>
                    </b-form-group>
                  </div>

                  <Qiz
                    v-if="typeContentSelected === 'QUIZ'"
                    @addedQuestion="addedQuestion"
                    @removedQuestion="removedQuestion"
                    :questions="this.questions"
                    :inEdit="this.editLesson"
                    @closeModalOptions="closeModalOptions"
                  />
                  <div>
                    <div
                      class="spaceInputs removemb"
                      v-if="
                        typeContentSelected === 'VIDEO' ||
                        typeContentSelected === 'AUDIO'
                      "
                    >
                      <!-- <div class="removeLessonVideo" @click="removeCourse()">
                                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M18 6L6 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                          <path d="M6 6L18 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                      </svg>
                                  </div> -->
                      <b-form-group
                        label="Arquivo da Aula"
                        label-for="name-aula"
                        id="step15"
                      >
                        <div
                          class="btn-openModal"
                          @click="showModalUpload(ModuleLessons.id)"
                          v-if="midialessonpreview === null"
                        >
                          <p class="text">
                            Clique e adicione um
                            {{
                              typeContentSelected === 'AUDIO'
                                ? 'Audio'
                                : 'Vídeo'
                            }}
                          </p>
                        </div>
                        <div class="" v-else-if="mediaTypeLesson === 'audio'">
                          <div
                            class="btn-openModal mt-2 mb-3"
                            @click="showModalUpload(ModuleLessons.id)"
                            v-if="
                              mediaTypeLesson === 'audio' &&
                              midialessonpreview !== null
                            "
                          >
                            <p class="text">Clique e adicione um Audio</p>
                          </div>
                          <div v-for="item in AllAudios" :key="item.id">
                            <div class="btn-openModalPreview mb-2">
                              <div>
                                <img
                                  src="@/assets/img/audio.png"
                                  class="imgAudio"
                                  alt="audio"
                                />
                                <p>{{ item.title }}</p>
                              </div>
                              <div
                                class="removeAttr"
                                @click="removeAudio(item.id)"
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M18 6L6 18"
                                    stroke="black"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M6 6L18 18"
                                    stroke="black"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="btn-openModalPreview" v-else>
                          <iframe
                            class="imagePreviewYt animationOpacity"
                            height="100%"
                            v-if="
                              mediaTypeLesson === 'panda' &&
                              midialesson !== 'panda'
                            "
                            width="50%"
                            :src="midialesson + '&controls=play-larg'"
                            style="border: 0; pointer-events: all"
                          ></iframe>
                          <div
                            v-else-if="
                              mediaTypeLesson === 'panda' &&
                              midialesson === 'panda'
                            "
                          >
                            <img
                              src="@/assets/img/iframe.png"
                              class="imgAudio"
                              alt="audio"
                            />
                          </div>
                          <div v-else-if="mediaTypeLesson === 'audio'">
                            <img
                              src="@/assets/img/audio.png"
                              class="imgAudio"
                              alt="audio"
                            />
                            <p>{{ midialessontitle }}</p>
                          </div>
                          <img
                            v-else
                            class="imagePreviewYt animationOpacity"
                            @click="showModalUploadItem(ModuleLessons.id)"
                            :src="midialessonpreview"
                            :alt="midialessonpreview"
                          />
                          <div
                            class="removeLessonVideo"
                            @click="removeCourse()"
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M18 6L6 18"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M6 6L18 18"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                        </div>
                      </b-form-group>
                    </div>
                    <div class="lineDivAtt"></div>
                    <div class="spaceInputs2">
                      <b-form-group
                        label="Categoria (Opcional)"
                        label-for="name-aula"
                      >
                        <div>
                          <multiselect
                            noOptions="Sem Categoria, Escreva uma!"
                            tag-placeholder="Adicionar nova Categoria"
                            @tag="addTag"
                            placeholder="Selecione uma Categoria"
                            selectedLabel=""
                            deselectLabel="Pressione Enter"
                            :close-on-select="true"
                            selectLabel="Pressione Enter"
                            track-by="id"
                            :multiple="false"
                            :taggable="true"
                            v-model="categoryLesson"
                            :options="optionsLessonCategory"
                          ></multiselect>
                        </div>
                      </b-form-group>
                    </div>

                    <div
                      class="spaceInputs removemb"
                      v-if="
                        clubVersion !== 'v2' &&
                        (typeContentSelected === 'VIDEO' ||
                          typeContentSelected === 'AUDIO')
                      "
                    >
                      <b-form-group
                        label="Thumb Customizada (Opcional)"
                        label-for="name-aula"
                      >
                        <div
                          class="btn-openModal"
                          @click="showModalUploadThumb(ModuleLessons.id)"
                          v-if="midialessonthumb === null"
                        >
                          <p class="text">Clique e adicione uma Thumb</p>
                        </div>
                        <div
                          class="btn-openModalPreview"
                          @click="showModalUploadThumb(ModuleLessons.id)"
                          v-else
                        >
                          <img
                            class="imagePreviewYt animationOpacity"
                            :src="midialessonthumb.url"
                            :alt="midialessonthumb.url"
                          />
                        </div>
                      </b-form-group>
                    </div>
                    <div
                      class="spaceInputs"
                      v-if="
                        typeContentSelected === 'VIDEO' ||
                        typeContentSelected === 'AUDIO' ||
                        typeContentSelected === 'TEXT'
                      "
                    >
                                            <!-- Description -->
                                            <b-form-group
                        class="description-container"
                        label="Descrição"
                        label-for="description"
                      >
                        <div
                          class="description-length"
                          :class="{ ok: descriptionLessonLength >= 200 }"
                          v-if="descriptionLessonLength"
                        >
                          {{ descriptionLessonLength }}
                        </div>

                        <b-form-textarea
                          class="description-textarea"
                          id="description"
                          name="description"
                          v-model="descricaoAula"
                          style="overflow-y: scroll !important;"
                          placeholder="Descrição da aula"
                        ></b-form-textarea>
                        <div class="mark-down d-flex justify-content-between mt-2">
                          <p class="info-mark">Descrição formatada em MarkDown</p>
                          <div
                            class="open-mark"
                            @click="openModal('editor-markdown')"
                          >
                            Formatar descrição
                        </div>
                        </div>
                      </b-form-group>
                      <EditorMarkDown :text_parent="descricaoAula" from="new" @created="descricaoAula = $event" @updated="descricaoAula = $event" />

                    </div>
                    <div
                      class="spaceInputs removemb"
                      v-if="
                        typeContentSelected === 'VIDEO' ||
                        typeContentSelected === 'AUDIO' ||
                        typeContentSelected === 'TEXT'
                      "
                    >
                      <div>
                        <div class="lineDivAtt"></div>
                        <b-form-group
                          label="Arquivos Adicionais"
                          label-for="complement"
                        >
                          <div class="totalAtt">
                            Total de Arquivos: {{ quantidadeAtt }}
                          </div>
                          <div>
                            <div
                              class="btn-openModalPreview spaceAttList"
                              v-for="attach in AllAttachments"
                              :key="attach.id"
                            >
                              <iframe
                                v-if="attach.mime === 'application/pdf'"
                                class="imagePreviewCapa animationOpacity"
                                height="100%"
                                width="100%"
                                :src="attach.cdn_url"
                              ></iframe>
                              <div v-else-if="attach.mime === 'audio/mpeg'">
                                <img
                                  src="@/assets/img/audio.png"
                                  class="imgAudio"
                                  alt="audio"
                                />
                                <p>{{ attach.title }}</p>
                              </div>
                              <img
                                loading="lazy"
                                class="imagePreviewCapa animationOpacity"
                                v-else-if="attach.mime === 'application/zip'"
                                src="https://app.greenn.club/zip.png"
                                :alt="attach.title"
                              />
                              <img
                                loading="lazy"
                                class="imagePreviewCapa animationOpacity"
                                v-else-if="
                                  attach.mime === 'application/msword' ||
                                  attach.mime ===
                                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                "
                                src="https://app.greenn.club/doc.png"
                                :alt="attach.title"
                              />
                              <img
                                loading="lazy"
                                class="imagePreviewCapa animationOpacity"
                                v-else-if="
                                  attach.mime === 'application/vnd.ms-excel' ||
                                  attach.mime ===
                                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                "
                                src="https://app.greenn.club/xls.png"
                                :alt="attach.title"
                              />
                              <img
                                v-else
                                class="imagePreviewCapa animationOpacity"
                                :src="attach.cdn_url"
                                :alt="attach.title"
                              />
                              <div
                                class="removeAttr"
                                @click="removeAttr(attach.id)"
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M18 6L6 18"
                                    stroke="black"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M6 6L18 18"
                                    stroke="black"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                        </b-form-group>
                      </div>
                      <div>
                        <b-form-group label="" label-for="complement">
                          <div
                            class="btn-openModal"
                            @click="showModalUploadAditional()"
                            v-if="
                              midiaaditionalurl === '' ||
                              midiaaditionalurl === null
                            "
                          >
                            <p class="text">Faça o Upload dos Complementos</p>
                          </div>
                          <div
                            class="btn-openModalPreview"
                            @click="showModalUploadAditional()"
                            v-else
                          >
                            <iframe
                              v-if="midiaaditionalurltype === 'application/pdf'"
                              class="imagePreviewCapa animationOpacity"
                              height="100%"
                              width="100%"
                              :src="midiaaditionalurl"
                            ></iframe>
                            <img
                              v-else
                              class="imagePreviewCapa animationOpacity"
                              :src="midiaaditionalurl"
                              :alt="midiaaditionalurl"
                            />
                          </div>
                        </b-form-group>
                        <div
                          v-if="
                            midiaaditionalurl === '' ||
                            midiaaditionalurl === null
                          "
                        ></div>
                        <div class="lineDivAtt"></div>
                      </div>
                    </div>
                    <div class="spaceInputs" v-if="editLesson === false">
                      <div class="flexBtn">
                        <button
                          class="btn-cancela"
                          @click.prevent="notSaveLesson()"
                        >
                          Cancelar
                        </button>
                        <button
                          class="btn-rascunho"
                          @click.prevent="createAula(ModuleLessons.id, 'draft')"
                        >
                          Salvar Rascunho
                        </button>
                      </div>
                      <button
                        class="btn-criar"
                        id="step17"
                        @click.prevent="
                          createAula(ModuleLessons.id, 'published')
                        "
                      >
                        Publicar Aula
                      </button>
                    </div>
                    <div class="spaceInputs" v-else>
                      <div class="flexBtn">
                        <button
                          class="btn-cancela"
                          @click.prevent="notSaveLesson()"
                        >
                          Cancelar
                        </button>
                        <button
                          class="btn-rascunho"
                          @click.prevent="saveEditLesson('draft')"
                        >
                          Salvar Rascunho
                        </button>
                      </div>
                      <button
                        class="btn-criar"
                        @click.prevent="saveEditLesson('published')"
                      >
                        Publicar Aula
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center mt-5" v-if="loading">
              <b-spinner label="Loading..."></b-spinner>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </SiteTemplate>
</template>
<script>
import SiteTemplate from '@/templates/SiteTemplate.vue';
import SiteTemplateV2 from '@/templates/SiteTemplateV2.vue';
import EditorMarkDown from "@/components/modals/EditorMarkDown";

import ModalUpload from '@/components/ModalUpload.vue';
import CourseService from '@/services/resources/CourseService';
import { ToggleButton } from 'vue-js-toggle-button';
const serviceCourse = CourseService.build();
import { quillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import notify from '@/services/libs/notificacao';
import draggable from 'vuedraggable';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import Qiz from '@/components/TypeContent/Quiz.vue';

var toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike', 'link'],
  ['blockquote', 'code-block'],
  [{ header: 1 }, { header: 2 }],
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ direction: 'rtl' }],
  [{ size: ['small', false, 'large', 'huge'] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ color: [] }, { background: [] }],
  [{ font: [] }],
  [{ align: [] }],
];

export default {
  components: {
    SiteTemplate,
    SiteTemplateV2,
    ModalUpload,
    ToggleButton,
    quillEditor,
    draggable,
    Multiselect,
    Qiz,
    EditorMarkDown
  },
  data() {
    return {
      midiaPdfId: '',
      midiaPdfUrl: '',
      midiapdfurltype: '',
      nameCourse: '',
      nameModulo: '',
      resumoCourse: '',
      descricaoCourse: '',
      categoryCourse: '',
      step: 0,
      AllCategory: 1,
      data: 'introducao',
      midiaintroducao: '',
      midiacapa: '',
      midiaaulas: '',
      module_id_edit: '',
      ModuleLessons: '',
      totalModules: '',
      nameAula: '',
      descricaoAula: '',
      AllAulas: [],
      editNameModule: '',
      editModuleModal: false,
      editActualModule: '',
      cursoAtual: '',
      moduleAtual: '',
      addAula: false,
      myDataVariable: true,
      existParans: true,
      midialessonpreview: null,
      midialesson: '',
      editLesson: false,
      idEditLesson: '',
      mediaTypeLesson: 'text',
      midialessontitle: '',
      midiaaditionalurl: '',
      midiaaditional: '',
      attatchments: false,
      nameLesson: '',
      quantidadeAtt: 0,
      AllAttachments: '',
      durationToEdit: '',
      editorOption: {
        modules: {
          toolbar: toolbarOptions,
        },
      },
      enabled: true,
      dragging: false,
      midialessonthumb: null,
      midialessonthumbid: null,
      categoryLesson: null,
      optionsLessonCategory: [],
      midiaaditionalurltype: '',
      mediaPandaId: '',
      typeContentSelected: 'VIDEO',
      optionsTypeContent: [
        
      ],
      quiz: {},
      questions: {},
      AllAudios: [],
      loading: false,
    };
  },
  computed: {
    descriptionLessonLength() {
      return this.descricaoAula?.length || 0;
    },
    draggingInfo() {
      return this.dragging ? 'under drag' : '';
    },
    theme() {
      return this.$store.state.globalSettings.theme;
    },
    clubVersion() {
      return this.$store.state.globalSettings.clubVersion;
    },
  },
  methods: {
    openModal(modal) {
      console.log('chegou aq');
      this.$root.$emit('bv::show::modal', modal, '#btnShow');
    },
    verifyIfIsQuiz() {
      setTimeout(() => {
        if (this.typeContentSelected == 'QUIZ' && !this.editLesson) {
          this.createAula(this.ModuleLessons.id, 'draft', true)
        } else {
          this.clearMidiaLesson();
        }
      }, 300);
    },
    clearMidiaLesson() {
      this.midialessonpreview = null;
      this.mediaTypeLesson = "",
      this.mediaType = "",
      this.midialesson = '';
    },
    converteDuration(segundos) {
      if (!segundos) return '';
      let horas = Math.floor(segundos / 3600);
      let minutos = Math.floor((segundos - horas * 3600) / 60);
      segundos = segundos - horas * 3600 - minutos * 60;

      let resultado = '';

      if (horas > 0) {
        if (horas < 10) {
          resultado += '0';
        }
        resultado += horas + ':';
      }

      if (minutos < 10) {
        resultado += '0';
      }
      resultado += minutos + ':';

      if (segundos < 10) {
        resultado += '0';
      }
      resultado += segundos;

      return resultado;
    },
    removeCourse() {
      let editLesson = this.editLesson;
      if (editLesson === true) {
        let data = {
          id: {
            id:
              this.cursoAtual +
              '/module/' +
              this.moduleAtual +
              '/lesson/' +
              this.idEditLesson,
          },
          data: {
            course_id: this.cursoAtual,
            midia_id: null,
            mediaType: 'text',
            source: null,
            duration: null,
            thumb: null,
            status: 'draft',
          },
        };
        this.loading = true;
        serviceCourse
          .postID2(data)
          .then(() => {
            this.midialessonpreview = null;
            this.mediaTypeLesson = 'text';
            this.midialesson = '';
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.midialessonpreview = null;
        this.mediaTypeLesson = 'text';
        this.midialesson = '';
      }
    },
    addTag(newTag) {
      this.optionsLessonCategory.push(newTag);
    },
    getLessonCategory() {
      serviceCourse
        .read(
          this.cursoAtual + '/module/' + this.moduleAtual + '/small-category',
        )
        .then((resp) => {
          var array = [];
          resp.filter(function (item) {
            array.push(item.small_category);
          });
          this.optionsLessonCategory = array;
        });
    },
    viewCourse() {
      serviceCourse.read(this.cursoAtual + '/module').then((resp) => {
        if (resp.length === 0) {
          notify('erro', 'Nenhum Módulo Configurado!');
          return false;
        }
        let routeData = this.$router.resolve('/curso/' + this.cursoAtual);
        window.open(routeData.href, '_blank');
      });
    },
    viewLesson(lessonId) {
      let routeData = this.$router.resolve(
        '/curso/' +
          this.cursoAtual +
          '/modulo/' +
          this.moduleAtual +
          '/aula/' +
          lessonId,
      );
      window.open(routeData.href, '_blank');
    },
    onEnd: function () {
      var array = [];
      var lessons = document.querySelectorAll('.viewAulas');
      for (let i = 0; i < lessons.length; i++) {
        const lessonId = lessons[i].getAttribute('data-id');
        array.push(parseInt(lessonId));
      }
      let data = {
        id: this.cursoAtual + '/module/' + this.moduleAtual + '/lesson/order',
        array,
      };
      this.loading = true;
      serviceCourse
        .postIDArray(data)
        .then(() => {
          this.getLesson();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async saveAtt() {
      if (this.idEditLesson === '' || this.idEditLesson === null) {
        await this.createAulaToAtt();
      } else {
        let data = {
          id: {
            id:
              this.cursoAtual +
              '/module/' +
              this.moduleAtual +
              '/lesson/' +
              this.idEditLesson +
              '/attachment',
          },
          data: {
            media_id: this.midiaaditional,
          },
        };
        this.loading = true;
        serviceCourse
          .postID2(data)
          .then(() => {
            this.midiaaditional = '';
            this.midiaaditionalurl = '';
            this.getAttatchments(this.idEditLesson);
            notify('sucesso', 'Arquivo Adicional salvo com Sucesso!');
            this.loading = false;
          })
          .catch((err) => {
            var error = JSON.parse(err.response.data);
            var msg = '';
            for (var indice in error) {
              msg += error[indice][0] + '<br>';
              if (
                msg ===
                'O valor informado para o campo id da mídia já está em uso.<br>'
              ) {
                this.getAttatchments(this.idEditLesson);
                this.midiaaditional = '';
                this.midiaaditionalurl = '';
              }
            }
            if (msg !== '') {
              notify('erro', msg);
            }
            this.loading = false;
          });
      }
    },
    createAulaToAtt() {
      if (this.idEditLesson === '' || this.idEditLesson === null) {
        let havePrefixUrl = false;

        try {
          havePrefixUrl =
            this.midialesson.split('?')[0] === 'https://www.youtube.com/watch'
              ? true
              : false;
        } catch (error) {
          havePrefixUrl = false;
        }

        var baseUrl = '';
        if (this.mediaTypeLesson === 'youtube' && !havePrefixUrl) {
          baseUrl = 'https://www.youtube.com/watch?v=';
        } else if (this.mediaTypeLesson === 'youtube' && havePrefixUrl) {
          baseUrl = '';
        } else if (this.mediaTypeLesson === 'vimeo') {
          baseUrl = '';
        } else if (this.mediaTypeLesson === 'iframe') {
          baseUrl = '';
        } else if (this.mediaTypeLesson === 'panda') {
          baseUrl = '';
        } else if (this.mediaTypeLesson === 'pdf') {
          baseUrl = '';
        } else if (this.mediaTypeLesson === 'audio') {
          baseUrl = '';
        } else {
          baseUrl = '';
          this.mediaTypeLesson = 'text';
        }

        var thumbVideo = '';
        if (this.midialessonthumb !== null) {
          thumbVideo = this.midialessonthumbid;
          // }else{
          //     thumbVideo = this.midialessonthumb.url
        }

        var dataSource = baseUrl + this.midialesson;
        if (
          this.midialesson === '' ||
          this.midialesson === null ||
          this.midialesson === undefined
        ) {
          dataSource = '';
        }

        let data = {
          id: {
            id: this.cursoAtual + '/module/' + this.moduleAtual + '/lesson',
          },
          data: {
            course_id: this.cursoAtual,
            title: this.nameAula,
            duration: this.vimeoduration,
            mediaType: this.mediaTypeLesson,
            source: dataSource,
            content: this.descricaoAula,
            thumb: this.midialessonpreview,
            custom_thumb: thumbVideo,
            small_category: this.categoryLesson,
            status: 'draft',
          },
        };
        if (data.data.title === '') {
          data.data.title = this.nameAula;
          return;
        }
        if (this.mediaTypeLesson === 'panda') {
          data.data.media_id = this.mediaPandaId;
          data.data.source = '';
        }

        if (this.mediaTypeLesson === 'pdf') {
          data.data.mediaType = 'pdf';
          data.data.source = this.midiaPdfUrl
          data.data.media_id = this.midiaPdfId
          console.log('mediaType',this.mediaType);
        }
        console.log('mediaType',this.mediaType);



        this.loading = true;
        serviceCourse
          .postID2(data)
          .then((resp) => {
            this.idEditLesson = resp.id;
            if (this.mediaTypeLesson === 'audio') {
              var audios = this.AllAudios;
              for (let i = 0; i < audios.length; i++) {
                const audio = audios[i];

                let dataAudio = {
                  id: {
                    id:
                      this.cursoAtual +
                      '/module/' +
                      this.moduleAtual +
                      '/lesson/' +
                      this.idEditLesson +
                      '/audio',
                  },
                  data: {
                    media_id: audio.id,
                  },
                };
                serviceCourse.postID2(dataAudio);
              }
            }
            this.getModules();
            this.editLesson = true;
            this.saveAtt();
            this.loading = false;
          })
          .catch((err) => {
            var error = JSON.parse(err.response.data);
            var msg = '';
            for (var indice in error) {
              msg += error[indice][0] + '<br>';
            }
            if (msg !== '') {
              // notify('erro', msg);
            }
            this.openCreateAula();
            this.loading = false;
          });
      } else {
        this.getModules();
        this.editLesson = true;
        this.saveAtt();
      }
    },
    removeAudio(audioId) {
      this.AllAudios.find((item, index) => {
        if (item.id === audioId) {
          this.AllAudios.splice(index, 1);
          return item;
        }
      });

      this.loading = true;
      serviceCourse
        .destroy(
          this.cursoAtual +
            '/module/' +
            this.moduleAtual +
            '/lesson/' +
            this.idEditLesson +
            '/audio/' +
            audioId,
        )
        .then(() => {
          this.getAudios(this.idEditLesson);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    removeAttr(attachId) {
      this.loading = true;
      serviceCourse
        .destroy(
          this.cursoAtual +
            '/module/' +
            this.moduleAtual +
            '/lesson/' +
            this.idEditLesson +
            '/attachment/' +
            attachId,
        )
        .then(() => {
          this.getAttatchments(this.idEditLesson);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getAudios(lesson) {
      if (lesson.id !== undefined) {
        this.nameLesson = lesson.title;
      }
      serviceCourse
        .read(
          this.cursoAtual +
            '/module/' +
            this.moduleAtual +
            '/lesson/' +
            this.idEditLesson +
            '/audio',
        )
        .then((resp) => {
          this.AllAudios = resp;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getAttatchments() {
      this.loading = true;
      serviceCourse
        .read(
          this.cursoAtual +
            '/module/' +
            this.moduleAtual +
            '/lesson/' +
            this.idEditLesson +
            '/attachment',
        )
        .then((resp) => {
          this.AllAttachments = resp;
          this.quantidadeAtt = resp.length;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    enventSwitchStatus() {
      var xxxStatus = '';
      if (this.myDataVariable === true) {
        xxxStatus = 'published';
      } else {
        xxxStatus = 'draft';
      }
      this.loading = true;
      serviceCourse
        .postID({ id: this.cursoAtual, status: xxxStatus })
        .then(() => {
          this.getCourse();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    notSaveLesson() {
      this.nameAula = '';
      this.descricaoAula = '';
      this.descricaoAula = '';
      this.addAula = false;
      this.editLesson = false;
      this.idEditLesson = '';
      this.midialessonthumbid = null;
      this.midialessonthumb = null;
      this.typeContentSelected = null;
      this.quiz = {};
      this.questions = {};
      this.getLesson();
      if (this.$route.query.lesson_id) {
        this.$router.push(
          '/config_curso/' +
            this.$route.params.curso +
            '/module/' +
            this.$route.params.module +
            '/lesson',
        );
      }
    },
    saveEditLesson(xStatus) {
      console.log('mediaTypeLesson', this.mediaTypeLesson)
      console.log('typeContentSelected', this.typeContentSelected)

      let data = {};

      if (this.typeContentSelected === 'QUIZ') {
        data = {
          id: {
            id:
              this.cursoAtual +
              '/module/' +
              this.moduleAtual +
              '/lesson/' +
              this.idEditLesson,
          },
          data: {
            course_id: this.cursoAtual,
            title: this.nameAula,
            status: xStatus,
            mediaType: 'quiz',
            source: 'quiz',
            content: this.descricaoAula,
            small_category: this.categoryLesson,
          },
        };
      } else {
        let havePrefixUrl = false;

        try {
          havePrefixUrl =
            this.midialesson.split('?')[0] === 'https://www.youtube.com/watch'
              ? true
              : false;
        } catch (error) {
          havePrefixUrl = false;
        }

        var baseUrl = '';
        if (this.mediaTypeLesson === 'youtube' && !havePrefixUrl) {
          baseUrl = 'https://www.youtube.com/watch?v=';
        } else if (this.mediaTypeLesson === 'youtube' && havePrefixUrl) {
          baseUrl = '';
        } else if (this.mediaTypeLesson === 'vimeo') {
          baseUrl = '';
        } else if (this.mediaTypeLesson === 'iframe') {
          baseUrl = '';
        } else if (this.mediaTypeLesson === 'panda') {
          baseUrl = '';
        } else if (this.mediaTypeLesson === 'pdf') {
          baseUrl = '';
        } else if (this.mediaTypeLesson === 'audio') {
          baseUrl = '';
        } else {
          baseUrl = '';
          this.mediaTypeLesson = 'text';
        }
        var thumbVideo = '';
        if (this.midialessonthumb !== null) {
          thumbVideo = this.midialessonthumbid;
          // }else{
          //     thumbVideo = this.midialessonthumb.url
        }

        var dataSource = baseUrl + this.midialesson;
        if (
          this.midialesson === '' ||
          this.midialesson === null ||
          this.midialesson === undefined
        ) {
          dataSource = '';
        }

        var duracaoUpdate = this.durationToEdit;
        if (
          this.durationToEdit === null ||
          (this.durationToEdit === '' && this.mediaTypeLesson === 'vimeo')
        ) {
          duracaoUpdate = this.vimeoduration;
        }

        data = {
          id: {
            id:
              this.cursoAtual +
              '/module/' +
              this.moduleAtual +
              '/lesson/' +
              this.idEditLesson,
          },
          data: {
            course_id: this.cursoAtual,
            title: this.nameAula,
            mediaType: this.mediaTypeLesson,
            source: dataSource,
            content: this.descricaoAula,
            duration: duracaoUpdate,
            thumb: this.midialessonpreview,
            custom_thumb: thumbVideo,
            status: xStatus,
            small_category: this.categoryLesson,
          },
        };

        if (this.mediaTypeLesson === 'panda') {
          data.data.media_id = this.mediaPandaId;
          data.data.source = '';
        }
        if (this.mediaTypeLesson === 'pdf') {
          data.data.mediaType = 'pdf';
          data.data.source = this.midiaPdfUrl
          data.data.media_id = this.midiaPdfId
        }
      }
      this.loading = true;
      serviceCourse
        .postID2(data)
        .then(() => {
          this.getLesson();
          notify('sucesso', 'Aula Salva com Sucesso!');
          if (this.mediaTypeLesson === 'audio') {
            var audios = this.AllAudios;
            for (let i = 0; i < audios.length; i++) {
              const audio = audios[i];

              let dataAudio = {
                id: {
                  id:
                    this.cursoAtual +
                    '/module/' +
                    this.moduleAtual +
                    '/lesson/' +
                    this.idEditLesson +
                    '/audio',
                },
                data: {
                  media_id: audio.id,
                },
              };
              serviceCourse.postID2(dataAudio);
            }
          }

          this.nameAula = '';
          this.descricaoAula = '';
          this.addAula = false;
          this.editLesson = false;
          this.midialessonthumbid = null;
          this.midialessonthumb = null;
          this.quiz = {};
          this.getModules();
          if (this.$route.query.lesson_id) {
            this.$router.push(
              '/config_curso/' +
                this.$route.params.curso +
                '/module/' +
                this.$route.params.module +
                '/lesson',
            );
          }
          this.loading = false;
        })
        .catch((err) => {
          var error = JSON.parse(err.response.data);
          var msg = '';
          for (var indice in error) {
            msg += error[indice][0] + '<br>';
          }
          if (msg !== '') {
            notify('erro', msg);
          }
          this.loading = false;
        });
    },
    deleteLesson(idLesson) {
      let data = {
        id:
          this.cursoAtual +
          '/module/' +
          this.moduleAtual +
          '/lesson/' +
          idLesson,
      };
      this.loading = true;
      serviceCourse
        .destroy(data)
        .then(() => {
          notify('sucesso', 'Aula excluída com Sucesso!');
          this.getLesson();
        })
        .catch(() => {
          notify('erro', 'Erro ao deletar Aula com Sucesso!');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async addedQuestion(data) {
      this.quiz = data;
      const questions = data.questions;
      if (this.editLesson) {
        questions.map(async (question) => {
          if (!question.text) return;
          await this.saveOneQuestion(this.idEditLesson, this.$route.params.module, question)
        });
        const cursoId = this.$route.params.curso;
        const moduleId = this.$route.params.module;
        await serviceCourse.get(cursoId + '/module/' + moduleId + '/lesson/' + this.idEditLesson);
      }
    },
    async createAula(idModule, xStatus, firstForQuiz) {
      let data = {};
      if (this.typeContentSelected === 'QUIZ') {
        data = {
          id: {
            id: this.cursoAtual + '/module/' + idModule + '/lesson',
          },
          data: {
            course_id: this.cursoAtual,
            title: this.nameAula,
            status: xStatus,
            mediaType: 'quiz',
            source: 'quiz',
            content: this.descricaoAula,
            small_category: this.categoryLesson,
          },
        };
      } else {
        let havePrefixUrl = false;

        try {
          havePrefixUrl =
            this.midialesson.split('?')[0] === 'https://www.youtube.com/watch'
              ? true
              : false;
        } catch (error) {
          havePrefixUrl = false;
        }

        var baseUrl = '';
        if (this.mediaTypeLesson === 'youtube' && !havePrefixUrl) {
          baseUrl = 'https://www.youtube.com/watch?v=';
        } else if (this.mediaTypeLesson === 'youtube' && havePrefixUrl) {
          baseUrl = '';
        } else if (this.mediaTypeLesson === 'vimeo') {
          baseUrl = '';
        } else if (this.mediaTypeLesson === 'iframe') {
          baseUrl = '';
        } else if (this.mediaTypeLesson === 'panda') {
          baseUrl = '';
        } else if (this.mediaTypeLesson === 'pdf') {
          baseUrl = '';
        } else if (this.mediaTypeLesson === 'audio') {
          baseUrl = '';
        } else {
          baseUrl = '';
          this.mediaTypeLesson = 'text';
        }

        var thumbVideo = '';
        if (this.midialessonthumb !== null) {
          thumbVideo = this.midialessonthumbid;
          // }else{
          //     thumbVideo = this.midialessonthumb.url
        }

        // if (
        //   this.midialesson === '' ||
        //   this.midialesson === 'https://www.youtube.com/watch?v='
        // ) {
        //   this.mediaTypeLesson = 'text';
        // }

        var dataSource = baseUrl + this.midialesson;
        if (
          this.midialesson === '' ||
          this.midialesson === null ||
          this.midialesson === undefined
        ) {
          dataSource = '';
        }

        var duracaoUpdate = this.durationToEdit;
        if (
          this.durationToEdit === null ||
          (this.durationToEdit === '' && this.mediaTypeLesson === 'vimeo')
        ) {
          duracaoUpdate = this.vimeoduration;
        }

        data = {
          id: {
            id: this.cursoAtual + '/module/' + idModule + '/lesson',
          },
          data: {
            course_id: this.cursoAtual,
            title: this.nameAula,
            duration: this.vimeoduration,
            mediaType: this.mediaTypeLesson,
            source: baseUrl + this.midialesson,
            content: this.descricaoAula,
            order: this.AllAulas.length,
            thumb: this.midialessonpreview,
            custom_thumb: thumbVideo,
            status: xStatus,
            small_category: this.categoryLesson,
          },
        };

        if (this.mediaTypeLesson === 'panda') {
          data.data.media_id = this.mediaPandaId;
          data.data.source = '';
        }
        if (this.mediaTypeLesson === 'audio') {
          data.data.mediaType = 'audio';
        }
        if (this.mediaTypeLesson === 'pdf') {
          data.data.mediaType = 'pdf';
          data.data.source = this.midiaPdfUrl;
          data.data.media_id = this.midiaPdfId;
        }
      }
      this.loading = true;
      await serviceCourse
        .postID2(data)
        .then(async (resp) => {
          this.module_id = '';
          if (this.typeContentSelected === 'QUIZ' && firstForQuiz) {
            this.editLessonFuntion(resp);
          } else if (this.typeContentSelected === 'QUIZ' && !firstForQuiz) {
            await this.saveQuestion(resp.id, idModule);
          } else {
            setTimeout(() => {
              this.onEnd();
            }, 1000);
            if (this.mediaTypeLesson === 'audio') {
              var audios = this.AllAudios;
              for (let i = 0; i < audios.length; i++) {
                const audio = audios[i];

                let dataAudio = {
                  id: {
                    id:
                      this.cursoAtual +
                      '/module/' +
                      idModule +
                      '/lesson/' +
                      resp.id +
                      '/audio',
                  },
                  data: {
                    media_id: audio.id,
                  },
                };
                serviceCourse.postID2(dataAudio);
              }
            }
            this.getModules();
            this.addAula = false;
            notify('sucesso', 'Aula Criada com Sucesso!');
            this.loading = false;
          }
        })
        .catch((err) => {
          var error = JSON.parse(err.response.data);
          var msg = '';
          for (var indice in error) {
            msg += error[indice][0] + '<br>';
          }
          if (msg !== '') {
            notify('erro', msg);
          }
          this.loading = false;
        });
    },
    async saveQuestion_old(lessonId, idModule) {
      let index = 0;
      this.loading = true;

      for await (let question of this.quiz.questions) {
        if (!question.text) {
          continue;
        }

        let data = {
          id: {
            id: `${
              this.cursoAtual
            }/module/${idModule}/lesson/${lessonId}/question/${
              question.id ?? ''
            }`,
          },
          data: {
            text: question.text,
          },
        };

        let options = question.answers;
        await serviceCourse
          .postID2(data)
          .then(async (respQuestion) => {
            for await (let option of options) {
              let dataOption = {
                id: {
                  id: `${
                    this.cursoAtual
                  }/module/${idModule}/lesson/${lessonId}/question/${
                    respQuestion.id
                  }/answer/${option.id ?? ''}`,
                },
                data: {
                  text: option.text,
                  is_correct: option.is_correct,
                },
              };
              await serviceCourse
                .postID2(dataOption)
                .then(() => {})
                .catch((e) => {
                  console.error(e.message);
                  notify('erro', 'Falha ao adicionar a Alternativa!');
                });
            }
            delete this.quiz.questions[index];
            index++;
          })
          .catch((e) => {
            this.loading = false;

            console.error(e.message);
            notify('erro', 'Falha ao adicionar a Questão!');
            return false;
          });
      }
      if (this.editLesson) {
        const cursoId = this.$route.params.curso;
        const moduleId = this.$route.params.module;
        await serviceCourse
          .get(cursoId + '/module/' + moduleId + '/lesson/' + lessonId)
          .then((resp) => {
            this.questions = resp.questions;
          });

        notify('sucesso', 'Questão editada com Sucesso!');
        this.loading = false;

        return true;
      }
      setTimeout(() => {
        this.onEnd();
      }, 1000);
      this.addAula = false;
      notify('sucesso', 'Aula Criada com Sucesso!');
      this.loading = false;
    },
    async mountDataQuestion(lessonId, idModule, question, order) {
      return {
        id: {
          id: `${
            this.cursoAtual
          }/module/${idModule}/lesson/${lessonId}/question/${
            question.id ?? ''
          }`,
        },
        data: {
          text: question.text,
          order
        },
      };
    },
    async mountDataAnswer(lessonId, idModule, question, option, order) {
      return {
        id: {
          id: `${
            this.cursoAtual
          }/module/${idModule}/lesson/${lessonId}/question/${
            question.id ?? ''
          }/answer/${option.id ?? ''}`,
        },
        data: {
          text: option.text,
          is_correct: option.is_correct,
          order
        },
      };
    },
    async saveOneAnswer(lessonId, idModule, question, option, order) {
      let data = await this.mountDataAnswer(lessonId, idModule, question, option, order);
      await serviceCourse.postID2(data);
      return;
    },
    async saveOneQuestion(lessonId, idModule, question) {
      const order = this.questions.length ? this.questions.length + 1 : 1
      let data = await this.mountDataQuestion(lessonId, idModule, question, order);
      const respQuestion = await serviceCourse.postID2(data);
      this.questions = this.quiz.questions;
      let answers = question.answers;
      answers.map(async (option, i) => {
        const order_answer = i+1;
        await this.saveOneAnswer(lessonId, idModule, respQuestion, option, order_answer);
      });
      return;
    },
    async saveQuestion(lessonId, idModule) {
      this.loading = true;
      const questions = this.quiz.questions.reverse();
      questions.map(async (question) => {
        if (!question.text) return;
        await this.saveOneQuestion(lessonId, idModule, question)
      });

      if (this.editLesson) {
        const cursoId = this.$route.params.curso;
        const moduleId = this.$route.params.module;
        await serviceCourse
          .get(cursoId + '/module/' + moduleId + '/lesson/' + lessonId)
          .then((resp) => {
            this.questions = resp.questions;
          });
        notify('sucesso', 'Questão editada com Sucesso!');
        this.loading = false;
      }
      setTimeout(() => {
        this.onEnd();
      }, 1000);
      this.addAula = false;
      notify('sucesso', 'Aula Criada com Sucesso!');
      this.loading = false;
      return;
    },
    async closeModalOptions(lessonId) {
      if (this.editLesson) {
        const cursoId = this.$route.params.curso;
        const moduleId = this.$route.params.module;
        await serviceCourse
          .get(cursoId + '/module/' + moduleId + '/lesson/' + lessonId)
          .then((resp) => {
            this.questions = resp.questions;
          });
      }
    },
    removedQuestion(data) {
      this.questions = data;
    },
    openCreateAula() {
      this.nameAula = '';
      this.descricaoAula = '';
      this.midialesson = '';
      this.midialessonpreview = null;
      this.addAula = true;
      this.midiaaditional = '';
      this.midiaaditionalurl = '';
      this.quantidadeAtt = 0;
      this.AllAttachments = '';
      this.midialessonthumbid = null;
      this.midialessonthumb = null;
      this.categoryLesson = null;
      this.AllAudios = [];
      this.idEditLesson = null;

      // serviceMember
      // .read('/meta?keys[]=onboarding')
      // .then((resp) => {
      // var data = resp.onboarding;
      //     if(data === null){
      //         this.nameAula = "Aula 1"
      //         this.descricaoAula = "<p>Descrição Aula 1</p>"
      //         this.mediaTypeLesson = "youtube"
      //         this.midialesson = "DpIjpxr7jZk";
      //         this.midialessonpreview = "https://i.ytimg.com/vi/DpIjpxr7jZk/default.jpg";
      //     }
      // })
    },
    editLessonFuntion(dataLesson) {
      this.midiaPdfUrl = dataLesson.source
      this.AllAudios = dataLesson.audios;
      this.nameAula = dataLesson.title;
      this.descricaoAula = dataLesson.description ?? "";
      this.midialesson = dataLesson.source;
      this.addAula = true;
      if (dataLesson.source) {
        this.midialessonpreview = dataLesson.thumb;
      }
      if (dataLesson.custom_thumb === null) {
        this.midialessonthumbid = null;
        this.midialessonthumb = null;
      } else {
        this.midialessonthumbid = dataLesson.custom_thumb.id;
        let data = {
          url: dataLesson.custom_thumb.cdn_url,
          id: dataLesson.custom_thumb.id,
        };
        this.midialessonthumb = data;
      }
      this.mediaTypeLesson = dataLesson.mediaType;
      this.midiaaditional = '';
      this.midiaaditionalurl = '';
      this.editLesson = true;
      this.idEditLesson = dataLesson.id;
      this.AllAttachments = dataLesson.attachments;
      this.quantidadeAtt = dataLesson.attachments.length;
      this.durationToEdit = dataLesson.duration;
      this.descricaoAula = dataLesson.content ?? "";
      this.categoryLesson = dataLesson.small_category;
      this.questions = {};
      this.quiz = {};
      if (dataLesson.mediaType === 'quiz') {
        this.typeContentSelected = 'QUIZ';
        this.questions = dataLesson.questions || [];
      } else if (dataLesson.mediaType === 'audio') {
        this.typeContentSelected = 'AUDIO';
      } else if (dataLesson.mediaType === 'panda') {
        this.mediaPandaId = dataLesson.media.id;
      } else if (dataLesson.mediaType === 'text') {
        this.typeContentSelected = 'TEXT';
      } else if (dataLesson.mediaType === 'pdf') {
        this.typeContentSelected = 'PDF';
        this.midiaPdfId = dataLesson.media.id;
      } else {
        this.typeContentSelected = 'VIDEO';
      }
      this.AllAudios = dataLesson.audios;
      this.getAudios(this.idEditLesson);
    },
    deleteModule(id) {
      if (this.totalModules !== 1) {
        this.loading = true;
        serviceCourse
          .destroy(this.cursoAtual + '/module/' + id)
          .then(() => {
            this.getModules();
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    showModalUploadThumb() {
      this.$root.$emit('uploadlessonthumb');
      this.$root.$emit('bv::show::modal', 'modal-upload', '#btnShow');
    },
    showModalUpload() {
      this.$root.$emit('uploadlesson', this.mediaTypeLesson);
      this.$root.$emit('bv::show::modal', 'modal-upload', '#btnShow');
    },
    showModalUploadAditional() {
      if (this.nameAula === '' || this.nameAula === null) {
        notify('erro', 'Necessario inserir o nome da aula!');
        return;
      }
      this.$root.$emit('uploadlessonadditional');
      this.$root.$emit('bv::show::modal', 'modal-upload', '#btnShow');
    },
    showModalUploadDocument() {
      this.$root.$emit('uploadlessonPdf');
      this.$root.$emit('bv::show::modal', 'modal-upload', '#btnSh ow');
    },
    showModalUploadItem() {
      let data = {
        type: this.mediaTypeLesson,
        link: this.midialesson,
      };
      this.$root.$emit('uploadlessonitem', data);
      this.$root.$emit('bv::show::modal', 'modal-upload', '#btnShow');
    },
    hideModalUpload() {
      this.$root.$emit('bv::hide::modal', 'modal-upload', '#btnShow');
    },
    getModules() {
      this.loading = true;
      serviceCourse
        .read(this.cursoAtual + '/module/' + this.moduleAtual)
        .then((resp) => {
          this.ModuleLessons = resp;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getCourse() {
      this.loading = true;
      let data = {
        id: this.cursoAtual,
      };
      serviceCourse
        .read(data)
        .then((resp) => {
          this.nameCourse = resp.title;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getLesson() {
      const params = this.$route.params.curso;
      const params2 = this.$route.params.module;
      let tath = this;
      if (
        params === null ||
        params === undefined ||
        params === '' ||
        params2 === null ||
        params2 === undefined ||
        params2 === ''
      ) {
        this.$router.push('/course');
      } else {
        this.loading = true;
        serviceCourse
          .read(tath.cursoAtual + '/module/' + params2 + '/lesson')
          .then((resp) => {
            this.AllAulas = resp;
            var aulas = this.AllAulas;
            this.getCourse();
            if (this.$route.query.lesson_id) {
              for (let i = 0; i < aulas.length; i++) {
                const element = aulas[i];
                if (element.id === parseInt(this.$route.query.lesson_id, 10)) {
                  if (element) this.editLessonFuntion(element);
                }
              }
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  mounted() {
    if (this.clubVersion == 'v1') {
      this.optionsTypeContent = [
        { value: 'TEXT', text: 'Em Texto' },
        { value: 'AUDIO', text: 'Em Audio' },
        { value: 'VIDEO', text: 'Em Vídeo' },
        { value: 'QUIZ', text: 'Questionário' },
      ]
    } else {
      this.optionsTypeContent = [
        { value: 'TEXT', text: 'Em Texto' },
        { value: 'AUDIO', text: 'Em Audio' },
        { value: 'VIDEO', text: 'Em Vídeo' },
        { value: 'PDF', text: 'Em PDF' },
        { value: 'QUIZ', text: 'Questionário' },
      ]
    }

    this.cursoAtual = this.$route.params.curso;
    this.moduleAtual = this.$route.params.module;
    this.getModules();
    this.getLesson();
    this.getLessonCategory();
    this.$root.$on('midiaaulas', (data) => {
      this.midiaaulas = parseInt(data);
    });
    this.$root.$on('uploadlessonnew', (data) => {
      this.AllAudios.push(data);
      this.midialesson = data.id;
      this.midialessonpreview = data.url;
      this.midialessontitle = data.title;
      this.mediaTypeLesson = data.type;
      if (data.type === 'audio/mpeg') {
        this.mediaTypeLesson = 'audio';
      }
    });
    this.$root.$on('midialessonyt', (data) => {
      this.mediaTypeLesson = 'youtube';
      this.midialesson = data.id.videoId;
      this.midialessonpreview = data.snippet.thumbnails.standard
        ? data.snippet.thumbnails.standard.url
        : data.snippet.thumbnails.default.url;
    });
    this.$root.$on('midialessonpanda', (data) => {
      this.mediaTypeLesson = 'panda';
      this.mediaPandaId = data.id;
      this.midialesson = data.cdn_url;
      this.midialessonpreview = data.cdn_url;
    });
    this.$root.$on('midialessonvimeo', (data) => {
      this.mediaTypeLesson = 'vimeo';
      this.midialesson = data.link;
      this.vimeoduration = data.duration;
      if (data.pictures.sizes[0].link === 'vimeolink') {
        this.midialessonpreview = 'https://app.greenn.club/vimeo.jpg';
      } else {
        this.midialessonpreview = data.pictures.sizes[0].link;
      }
    });
    this.$root.$on('midialessoniframe', (data) => {
      this.mediaTypeLesson = 'iframe';
      this.midialesson = data.link;
      this.vimeoduration = 0;
      this.midialessonpreview = 'https://app.greenn.club/iframe.png';
    });
    this.$root.$on('midiaadditional', (data) => {
      this.midiaaditional = parseInt(data.id);
      this.midiaaditionalurl = data.url;
      this.midiaaditionalurltype = data.type;
      this.saveAtt();
    });
    this.$root.$on('midiapdf', (data) => {
      console.log('data',data);
      this.mediaTypeLesson = 'pdf';
      this.midiapdfurltype = data.type;
      this.midialessonpreview = data.url;
      this.midiaPdfId = data.id
      this.midiaPdfUrl = data.url;
    });
    this.$root.$on('uploadlessonthumbnew', (data) => {
      this.midialessonthumbid = parseInt(data.id);
      this.midialessonthumb = data;
    });
    this.$root.$on('stepconcluirinitaula', () => {
      this.openCreateAula();
    });
    this.$root.$on('stepconcluiraula', () => {
      this.createAula(this.moduleAtual, 'published');
    });
  },
  beforeDestroy() {
    this.$root.$off('midiaadditional');
  },
};
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

.title-v2 {
  font-family: 'Montserrat Alternates' !important;
  font-style: normal !important;
  font-weight: 800 !important;
}

.createLessons {
  .removeLessonVideo {
    margin-left: 5px;
    opacity: 1;
    transition: 0.3s;
    svg {
      filter: var(--filtericon);
    }
  }
  .textSubcategory {
    font-size: 14px;
    font-weight: 600;
    color: var(--maincolor);
    margin-bottom: 3px;
  }
  .marginSubCategory {
    margin-left: 15px;
  }
  .spaceInputs2 {
    margin-bottom: 30px;
    label {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: var(--fontcolor);
    }
    .multiselect__tags {
      height: 55px;
      border-radius: 5px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #000000;
      overflow: hidden !important;
      resize: none !important;
      background: white;
      padding: 15px;
    }
    .multiselect__select:before {
      top: 95% !important;
    }
  }
  .lineDivAtt {
    height: 0.5px;
    background: #ededf0;
    margin-bottom: 20px;
    width: 100%;
  }
  .spaceAttList {
    margin-bottom: 2px;
    margin-top: 15px;
  }
  .totalAtt {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: var(--fontcolor2);
  }
  .quill-editor {
    background: #fff;
    border-radius: 5px;
    .ql-editor {
      min-height: 150px;
    }
    .ql-toolbar.ql-snow {
      border-radius: 5px 5px 0px 0px;
      button {
        color: var(--maincolor) !important;
      }
    }
    .ql-container.ql-snow {
      border-radius: 0px 0px 5px 5px;
    }
  }
  .removeLink {
    text-decoration: none;
  }
  .btn-openModalPreview {
    background: var(--maincolortrans);
    border: 2px dashed var(--maincolor);
    box-sizing: border-box;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
    height: 165px;
    .removeAttr {
      margin-left: 5px;
      opacity: 1;
      transition: 0.3s;
      svg {
        filter: var(--filtericon);
      }
    }
    img {
      border-radius: 3px;
    }
    iframe {
      width: fit-content;
      height: unset;
      border-radius: 3px;
    }
    .imagePreviewCapa {
      width: 100px;
      height: 100px;
      border-radius: 3px;
      overflow: hidden;
    }
  }
  .btn-openModalPreview:hover {
    .removeAttr {
      opacity: 1;
      transition: 0.3s;
    }
  }

  .navigate {
    .title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      color: var(--fontcolor) !important;
    }
    .lineDiv {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
      height: 0.5px;
      background: #ededf0;
    }
    .spaceBtn {
      padding: 4px;
      margin-top: 10px;
      .btnDesativar {
        background: rgba(33, 51, 210, 0.1);
        box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
        border-radius: 5px;
        border: none;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #2133d2;
        height: 45px;
        width: 100%;
      }
    }
    .spaceNav {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      transform: translateY(0px);
      transition: transform 0.44s ease, box-shadow 0.44s ease;
      .text {
        font-family: Montserrat;
        font-style: normal;
        font-size: 15px;
        color: #81858e;
      }
    }
    .spaceNav:hover {
      transform: translateY(2px);
      transition: transform 0.44s ease, box-shadow 0.44s ease;
    }
  }
  .editModuleClass {
    position: fixed;
    background: rgba(0, 0, 0, 0.24);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999999999999999999999;
    .contentAdd {
      z-index: 99999999999999999999999999;
      position: absolute;
      padding: 30px;
      right: 0;
      top: 25vh;
      width: 303px;
      height: 295px;
      background: #ffffff;
      border: solid 1px var(--bordercolor);
      box-sizing: border-box;
      border-radius: 3px;
      .close {
        cursor: pointer;
      }
      .space {
        margin-top: 40px;
        margin-bottom: 40px;
        input {
          border: 1px solid var(--bordercolor);
          box-sizing: border-box;
          border-radius: 5px;
          height: 40px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #ededf0;
          overflow: hidden !important;
          resize: none !important;
          background: white;
        }
        input:hover,
        input:focus {
          border: 1px solid var(--maincolor);
          box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
        }
        label {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          color: var(--fontcolor);
        }
        .btn-salvar {
          background: var(--maincolor);
          border-radius: 5px;
          border: none;
          width: 146px;
          font-family: Montserrat;
          color: #ffffff;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          height: 40px;
        }
      }
      .title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: var(--maincolor);
      }
    }
  }
  .hide {
    display: none !important;
  }
  .spaceWizzard {
    .viewAulas {
      .flexAula {
        display: flex;
        justify-content: space-between;
        .flex {
          display: flex;
          .action {
            position: relative;
            top: 5px;
          }
          .play {
            position: relative;
            top: 6px;
          }
        }
      }
      .textAula {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #c4c4c4;
        margin-left: 20px;
      }
      .textAula2 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        color: #c4c4c4;
        margin-left: 20px;
      }
      .timeAula {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #c4c4c4;
      }
      .actionAula {
        display: flex;
        justify-content: space-between;
        svg {
          position: relative;
          left: 24px;
          z-index: 2;
        }
        button.btn.dropdown-toggle.btn-secondary,
        button.btn.dropdown-toggle.btn-secondary:hover,
        button.btn.dropdown-toggle.btn-secondary:focus,
        button.btn.dropdown-toggle.btn-secondary:active,
        button.btn.dropdown-toggle.btn-secondary:focus-within,
        button.btn.dropdown-toggle.btn-secondary:focus-visible {
          background: rgba(255, 255, 255, 0) !important;
          border: rgba(0, 0, 0, 0) !important;
          box-shadow: none !important;
          z-index: 9;
          color: #303e3e00 !important;
          top: -14px;
        }
        a.dropdown-item {
          font-family: Montserrat !important;
          font-style: normal !important;
          font-weight: 600 !important;
          font-size: 14px !important;
          color: #333 !important;
        }
        .dropdown-item:hover,
        .dropdown-item:focus {
          color: #333 !important;
          text-decoration: none !important;
          background-color: #fff !important;
        }
      }
    }
    .blur1 {
      position: fixed;
      left: 1px;
      bottom: -60px;
    }
    .blur2 {
      position: fixed;
      right: 1px;
      top: 80px;
      transform: rotate(180deg);
    }
    .spaceInputsCreate {
      display: flex;
      justify-content: flex-end;
      position: relative;
      left: 5em;
      top: -2em;
      .btn-criar {
        background: var(--maincolor);
        border: 1px solid var(--maincolor);
        box-sizing: border-box;
        box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
        border-radius: 5px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        color: #ffffff;
        width: 100%;
        height: 55px;
        margin-top: 40px;
        width: 250px;
      }
    }
    .containerWizzardInputs {
      margin-left: auto;
      margin-right: auto;
      width: 95%;
      margin-top: 50px;
      .paddingNewModule {
        padding-left: 80px;
        padding-right: 80px;
        padding-top: 10px;
      }
      .btn-newModule {
        background: rgba(129, 133, 142, 0.03);
        border: 2px dashed #81858e;
        box-sizing: border-box;
        border-radius: 5px;
        height: 55px;
        cursor: pointer;
        .text {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #81858e;
          display: flex;
          justify-content: center;
          margin-top: 17px;
        }
      }
      .removemb {
        margin-bottom: 0px !important;
      }
      .actionModule {
        display: flex;
        justify-content: space-between;
        .titleModule {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          color: var(--maincolor);
          line-height: 3em;
        }
        svg {
          position: relative;
          left: 24px;
          z-index: 2;
        }
        button.btn.dropdown-toggle.btn-secondary,
        button.btn.dropdown-toggle.btn-secondary:hover,
        button.btn.dropdown-toggle.btn-secondary:focus,
        button.btn.dropdown-toggle.btn-secondary:active,
        button.btn.dropdown-toggle.btn-secondary:focus-within,
        button.btn.dropdown-toggle.btn-secondary:focus-visible {
          background: rgba(255, 255, 255, 0) !important;
          border: rgba(0, 0, 0, 0) !important;
          box-shadow: none !important;
          z-index: 9;
          color: #303e3e00 !important;
        }
        a.dropdown-item {
          font-family: Montserrat !important;
          font-style: normal !important;
          font-weight: 600 !important;
          font-size: 14px !important;
          color: #333 !important;
        }
        .dropdown-item:hover,
        .dropdown-item:focus {
          color: #333 !important;
          text-decoration: none !important;
          background-color: #fff !important;
        }
      }
      .lineDiv {
        background: #ededf0;
        transform: matrix(1, 0, 0, -1, 0, 0);
        height: 0.5px;
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .spaceModuleCard {
        width: 100%;
        background: var(--backgroundcolor);
        border: 0.5px solid #e5e5e5;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 32px;
        margin-bottom: 20px;
        cursor: pointer;
      }
      .spaceInputs {
        margin-bottom: 30px;
        .flexBtn {
          display: flex;
          gap: 35px;
        }
        .textInfo {
          font-family: Montserrat;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 15px;
          color: #81858e;
          margin-top: 5px;
        }
        .btn-criar {
          background: var(--maincolor);
          border: 1px solid var(--maincolor);
          box-sizing: border-box;
          box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
          border-radius: 5px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          color: #ffffff;
          width: 100%;
          height: 55px;
          margin-top: 40px;
        }
        .btn-att-add {
          background: rgba(33, 51, 210, 0.1);
          border: none;
          box-sizing: border-box;
          box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
          border-radius: 5px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          color: #2133d2;
          width: 100%;
          height: 55px;
          margin-top: 20px;
          margin-bottom: 20px;
        }
        .btn-cancela {
          background: #f7f7f7;
          border: 1px solid#f7f7f7;
          box-sizing: border-box;
          box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
          border-radius: 5px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          color: #81858e;
          width: 100%;
          height: 55px;
          margin-top: 40px;
        }
        .btn-rascunho {
          background: rgba(33, 51, 210, 0.1);
          border: none;
          box-sizing: border-box;
          box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
          border-radius: 5px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          color: #2133d2;
          width: 100%;
          height: 55px;
          margin-top: 40px;
        }
        .btn-openModal {
          background: var(--maincolortrans);
          border: 2px dashed var(--maincolor);
          box-sizing: border-box;
          border-radius: 5px;
          height: 55px;
          cursor: pointer;
          .text {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            color: var(--maincolor);
            display: flex;
            justify-content: center;
            margin-top: 17px;
          }
        }
        .gerenciarCat {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          letter-spacing: 0.2px;
          color: var(--maincolor);
          position: absolute;
          right: 25em;
          cursor: pointer;
        }
        select {
          border: 1px solid var(--bordercolor);
          box-sizing: border-box;
          border-radius: 5px;
          height: 55px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #6d767e;
          overflow: hidden !important;
          resize: none !important;
        }
        input,
        textarea {
          border: 1px solid var(--bordercolor);
          box-sizing: border-box;
          border-radius: 5px;
          height: 55px;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
          overflow: hidden !important;
          resize: none !important;
          background: white;
        }
        input:hover,
        select:hover,
        textarea:hover,
        input:focus,
        select:focus,
        textarea:focus {
          border: 1px solid var(--maincolor);
          box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: #000000;
        }
        label {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: var(--fontcolor2);
        }
      }
    }
    .containerWizzard {
      text-align: center;
      .title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 800;
        font-size: 32px;
        line-height: initial;
        align-items: center;
        color: var(--fontcolor) !important;
      }
    }
  }
  .containerx {
    width: 98%;
  }
}

.imgAudio {
  height: 80px;
  margin: 0 auto;
}

.description-container {
  position: relative;
}
.description-length {
  min-width: 30px;
  min-height: 30px;
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff0c37;
  background: rgba(255, 12, 55, 0.1);
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  top: -10px;
  right: 0;

  &.ok {
    color: var(--maincolor);
    background: var(--maincolortrans);
  }
}

.open-mark{
    color: #004392;
    font-weight: 600;
}

.info-mark {
    margin: 0;
    font-weight: 400;
    color: #81858e;
    font-size: 13px;
    line-height: 1.5;
}

.description-textarea {
  height: 150px !important;
}

</style>
